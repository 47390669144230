import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';

import { Link } from 'react-router-dom';

import Moment from 'react-moment';
import axios from 'axios';

import {
  Typography,
  Table,
  message,
  Row,
  Col,
  Divider,
  Button
} from 'antd';

import { IconContext } from "react-icons";
import {
    RiCheckboxMultipleBlankLine, RiExchangeLine, RiFileList2Line
} from 'react-icons/ri';

import { NotifyNetworkError } from './../common/Notifications';
import Stats from './../common/Stats';

const { Title, Text } = Typography;

const DBlocks = () => {

  const [dBlocks, setDBlocks] = useState([]);
  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [pagination, setPagination] = useState({pageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '50', '100'], current: 1});
  const [pendingTxs, setPendingTxs] = useState([]);
  const [pendingEntries, setPendingEntries] = useState([]);
  const [totalPendingTxs, setTotalPendingTxs] = useState(0);
  const [totalPendingEntries, setTotalPendingEntries] = useState(0);
  const [tableEntriesIsLoading, setTableEntriesIsLoading] = useState(true);
  const [tableTxsIsLoading, setTableTxsIsLoading] = useState(true);

  const getDBlocks = async (params = pagination) => {
    ReactGA.pageview(window.location.pathname);
    setTableIsLoading(true);
    try {
      const response = await axios.get('/explorer/dblocks', { params: { start: (params.current-1)*params.pageSize, limit: params.pageSize } });
      setDBlocks(response.data.result.Items);
      setPagination({...pagination, current: (response.data.start/response.data.limit)+1, pageSize: response.data.limit, total: response.data.total, showTotal: (total, range) => `${(params.current-1)*params.pageSize+1}-${Math.min(response.data.total, params.current*params.pageSize)} of ${response.data.total}`});
    }
    catch(error) {
        if (error.response) {
          message.error(error.response.data.error);
        } else {
          NotifyNetworkError();
        }
    }
    setTableIsLoading(false);
  }

  const getPendingTxs = async () => {
    try {
      const response = await axios.get('/explorer/pending_ftransactions', { params: { limit: 3 } });
      setPendingTxs(response.data.result.Items);
      setTotalPendingTxs(response.data.total);
    }
    catch(error) {
        if (error.response) {
          message.error(error.response.data.error);
        } else {
          NotifyNetworkError();
        }
    }
    setTableTxsIsLoading(false);
  }

  const getPendingEntries = async () => {
    try {
      const response = await axios.get('/explorer/pending_entries', { params: { limit: 3 } });
      setPendingEntries(response.data.result.Items);
      setTotalPendingEntries(response.data.total);
    }
    catch(error) {
        if (error.response) {
          message.error(error.response.data.error);
        } else {
          NotifyNetworkError();
        }
    }
    setTableEntriesIsLoading(false);
  }

  const columns = [
    {
      title: 'Height',
      key: 'dbHeight',
      className: 'code',
      width: 30,
      render: (row) => (
        <Link to={'/dblocks/' + row.keyMR}>
          {row.dbHeight}
        </Link>
      )
    },
    {
      title: 'Timestamp (UTC+'+ -(new Date().getTimezoneOffset() / 60) + ')',
      dataIndex: 'timestamp',
      className: 'code',
      width: 30,
      render: (timestamp) => (
        <nobr><Moment unix format="YYYY-MM-DD HH:mm" local>{timestamp*60}</Moment></nobr>
      )
    },
    {
      title: 'DBlock KeyMR',
      dataIndex: 'keyMR',
      className: 'code',
      render: (keyMR) => (
        <Link to={'/dblocks/' + keyMR}>
          <IconContext.Provider value={{ className: 'react-icons' }}>
            <RiCheckboxMultipleBlankLine />
          </IconContext.Provider>
          {keyMR}
        </Link>
      )
    },
    {
      title: 'Factoid Txs',
      dataIndex: 'factoidCount',
      className: 'code',
      align: 'center'
    },
    {
      title: 'Entry Blocks',
      dataIndex: 'blockCount',
      className: 'code',
      align: 'center',
      render: (blockCount) => (
        <span>{blockCount-3}</span>
      )
    },
    {
      title: 'Entries',
      dataIndex: 'entriesCount',
      className: 'code',
      align: 'center'
    },
  ];

  const columnsPendingEntries = [
    {
      title: 'Latest Pending Entries',
      render: (item) => (
        <div>
        <nobr>
        <Link to={'/entries/' + item.entryHash} className="code">
          <IconContext.Provider value={{ className: 'react-icons' }}>
            <RiFileList2Line />
          </IconContext.Provider>
          {item.entryHash}
        </Link>
        </nobr>
        <br />
        <nobr><Text type="secondary"><Moment fromNow>{item.createdAt}</Moment></Text></nobr>
        </div>
      )
    }
  ];

  const columnsPendingTxs = [
    {
      title: 'Latest Pending Transactions',
      render: (item) => (
        <div>
        <nobr>
        <Link to={'/transactions/' + item.txId} className="code">
          <IconContext.Provider value={{ className: 'react-icons' }}>
            <RiExchangeLine />
          </IconContext.Provider>
          {item.txId}
        </Link>
        </nobr>
        <br />
        <nobr>
          <Text type="secondary"><Moment fromNow>{item.createdAt}</Moment></Text>
          <Divider type="vertical" />
          <span className="code">{Math.max(item.totalInputs/100000000, item.totalOutputs/100000000)} FCT</span>
        </nobr>
        </div>
      )
    }
  ];

  useEffect(() => {
    document.title = "DBlocks | Factom Realtime Explorer";
    getDBlocks();
    getPendingEntries();
    getPendingTxs();
  }, []);

  return (
    <div>

      <div className="stats stats-minheight145">
        <Stats />
      </div>

      <Row gutter={[16, 16]}>
      <Col span={24}>

      <Title level={2}>Directory Blocks</Title>

      <Table
        dataSource={dBlocks}
        columns={columns}
        pagination={pagination}
        rowKey="height"
        loading={tableIsLoading}
        onChange={getDBlocks}
        scroll={{ x: 'max-content' }}
      />

      </Col>
      </Row>

      {dBlocks.length > 0 ? (
      <Row gutter={[16, 16]}>
      
      <Col xs={24} sm={24} md={24} lg={24} xl={12}>

      <Title level={4} style={{ marginTop: 10 }}>
        <IconContext.Provider value={{ className: 'react-icons' }}>
          <RiFileList2Line />
        </IconContext.Provider>
        Pending Entries
      </Title>
      <div style={{ marginTop: -10, marginBottom: 15 }}>
        <Text>
          Entries to be included in the next Directory Block
        </Text>
      </div>
      <Table
        dataSource={pendingEntries}
        columns={columnsPendingEntries}
        pagination={false}
        rowKey="entryHash"
        loading={tableEntriesIsLoading}
        scroll={{ x: 'max-content' }}
        footer={() => <Link to={'/pending'}><Button block type="secondary">View all pending entries <Text type="secondary">({totalPendingEntries})</Text></Button></Link>}
      />

      </Col>

      <Col xs={24} sm={24} md={24} lg={24} xl={12}>

      <Title level={4} style={{ marginTop: 10 }}>
        <IconContext.Provider value={{ className: 'react-icons' }}>
          <RiExchangeLine />
        </IconContext.Provider>
        Pending Transactions
      </Title>
      <div style={{ marginTop: -10, marginBottom: 15 }}>
        <Text>
          Transactions to be included in the next Directory Block
        </Text>
      </div>
      <Table
        dataSource={pendingTxs}
        columns={columnsPendingTxs}
        pagination={false}
        rowKey="txId"
        loading={tableTxsIsLoading}
        scroll={{ x: 'max-content' }}
        footer={() => <Link to={'/pending'}><Button block type="secondary">View all pending transactions <Text type="secondary">({totalPendingTxs})</Text></Button></Link>}
      />

      </Col>

      </Row>
      ) : null
      }

    </div>
  );
}

export default DBlocks;
