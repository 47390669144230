import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';

import { Link } from 'react-router-dom';

import {
  Typography,
  Descriptions,
  Skeleton,
  Alert,
  Table,
  Tooltip
} from 'antd';

import { IconContext } from "react-icons";
import {
    RiCheckboxMultipleBlankLine, RiInformationLine, RiQuestionLine, RiExchangeBoxLine, RiExchangeLine
} from 'react-icons/ri';

import Moment from 'react-moment';
import axios from 'axios';

import { NotifyNetworkError } from './../common/Notifications';
import Count from './../common/Count';

const { Title, Text } = Typography;

const FBlock = ({ match }) => {

    const pagination = {defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '50', '100'], showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`};
    const [height, setHeight] = useState(null);
    const [fBlock, setFBlock] = useState(null);
    const [error, setError] = useState(null);

    const getFBlock = async (keyMR = keyMR) => {
        ReactGA.pageview(window.location.pathname);
        document.title = "FBlock " + keyMR + " | Factom Realtime Explorer";
        setFBlock(null);
        setError(null);
        try {
            const response = await axios.get('/explorer/fblocks/'+keyMR);
            setFBlock(response.data.result);
            setHeight(response.data.result.dbHeight);
        }
        catch(error) {
            setFBlock(null);
            if (error.response) {
                setError(error.response.data.error);
            } else {
                NotifyNetworkError();
            }
        }
    }

    const columns = [
      {
        title: '#',
        dataIndex: 'seqNumber',
        className: 'code',
        width: 30,
        sorter: (a, b) => a.seqNumber - b.seqNumber
      },
      {
        title: 'Transaction ID',
        dataIndex: 'txId',
        className: 'code',
        render: (txId) => (
          <Link to={'/transactions/' + txId}>
              <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiExchangeLine />
              </IconContext.Provider>
              {txId}
          </Link>
        )
      },
      {
        title: 'Inputs',
        dataIndex: 'totalInputs',
        className: 'code',
        sorter: (a, b) => a.totalInputs - b.totalInputs,
        render: (totalInputs) => (
          <span>{totalInputs/100000000} FCT</span>
        )
      },
      {
        title: 'Outputs',
        dataIndex: 'totalOutputs',
        className: 'code',
        sorter: (a, b) => a.totalOutputs - b.totalOutputs,
        render: (totalOutputs) => (
          <span>{totalOutputs/100000000} FCT</span>
        )
      },
      {
        title: 'ECs Created',
        dataIndex: 'totalOutECs',
        className: 'code',
        sorter: (a, b) => a.totalOutECs - b.totalOutECs,
        render: (totalOutECs) => (
          <span>{Math.round(totalOutECs/fBlock.exchRate)} EC</span>
        )
      },
      {
        title: 'Fees',
        className: 'code',
        render: (row) => {
          let fees = 0;
          if (row.totalInputs>0) { fees = row.totalInputs-row.totalOutputs-row.totalOutECs; }
          return (
            <span>{fees/100000000} FCT</span>
          )
        }
      },
    ];

    const prevFBlock = () => {
      getFBlock(fBlock.prevKeyMR);
      setHeight(height-1);
    }

    const nextFBlock = () => {
      getFBlock(fBlock.nextFBlock.keyMR);
      setHeight(height+1);
    }

    useEffect(() => {
      getFBlock(match.params.keymr);
    }, [match.params.keymr]);

    return (
        <div>
            <Title level={2}>Factoid Block {height!==null ? "#"+height : null}</Title>
            <Title level={4} type="secondary" style={{ marginTop: "-10px" }} className="break-all" copyable>{match.params.keymr}</Title>
                {fBlock ? (
                    <div>
                        <Title level={4}>
                          <IconContext.Provider value={{ className: 'react-icons' }}>
                            <RiInformationLine />
                          </IconContext.Provider>
                          FBlock Info
                        </Title>
                        <Descriptions bordered column={1} size="middle">
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The block height of the parent DBlock, which indicates the length of the blockchain."><RiQuestionLine /></Tooltip></IconContext.Provider>Block</nobr> Height</span>}>
                                {fBlock.parentDBlock ? (
                                  <span className="code">
                                    <Link to={'/dblocks/' + fBlock.parentDBlock.keyMR}>
                                      {fBlock.dbHeight}
                                    </Link>
                                  </span>
                                ) :
                                  <span className="code">
                                    {fBlock.dbHeight}
                                  </span>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The sequence number of the current Factoid Block."><RiQuestionLine /></Tooltip></IconContext.Provider>Sequence</nobr> Number</span>}>
                                  <span className="code">
                                    {fBlock.dbHeight}
                                  </span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The date and time at which a block is created."><RiQuestionLine /></Tooltip></IconContext.Provider>Timestamp</nobr> <nobr>(UTC+{-(new Date().getTimezoneOffset() / 60)})</nobr></span>}>
                                {fBlock.parentDBlock ? (
                                    <span className="code"><Moment unix format="YYYY-MM-DD HH:mm" local>{fBlock.parentDBlock.timestamp*60}</Moment></span>
                                ) :
                                    <Text disabled>N/A</Text>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="Also known as Key Merkle Root. The hash of the current Factoid Block."><RiQuestionLine /></Tooltip></IconContext.Provider>KeyMR</nobr></span>}>
                                <span className="code break-all">{fBlock.keyMR}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The hash of the next Factoid Block."><RiQuestionLine /></Tooltip></IconContext.Provider>Next</nobr> FBlock</span>}>
                                    {fBlock.nextFBlock ? (
                                        <span className="code break-all">
                                            <Link to={'/fblocks/' + fBlock.nextFBlock.keyMR} onClick={nextFBlock}>
                                                <IconContext.Provider value={{ className: 'react-icons' }}>
                                                    <RiExchangeBoxLine />
                                                </IconContext.Provider>
                                                {fBlock.nextFBlock.keyMR}
                                            </Link>
                                        </span>
                                    ) : 
                                        <Text disabled>Not available yet</Text>
                                    }
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The hash of the previous Factoid Block."><RiQuestionLine /></Tooltip></IconContext.Provider>Previous</nobr> FBlock</span>}>
                                <span className="code break-all">
                                    {fBlock.prevKeyMR !== "0000000000000000000000000000000000000000000000000000000000000000" ? (
                                        <Link to={'/fblocks/' + fBlock.prevKeyMR} onClick={prevFBlock}>
                                            <IconContext.Provider value={{ className: 'react-icons' }}>
                                                <RiExchangeBoxLine />
                                            </IconContext.Provider>
                                            {fBlock.prevKeyMR}
                                        </Link>
                                    ) : 
                                        <span>
                                            {fBlock.prevKeyMR}
                                        </span>
                                    }
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The exchange rate for FCT→EC transactions in factoshis (Factoid^(-1e8))."><RiQuestionLine /></Tooltip></IconContext.Provider>Exchange</nobr> Rate</span>}>
                                <span className="code">{fBlock.exchRate} <Text type="secondary">(1 FCT = {Math.round(1/fBlock.exchRate*100000000)} EC)</Text></span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The hash of the Directory Block, that contains the current Factoid Block."><RiQuestionLine /></Tooltip></IconContext.Provider>Parent</nobr> DBlock</span>}>
                                    {fBlock.parentDBlock ? (
                                        <span className="code break-all">
                                          <Link to={'/dblocks/' + fBlock.parentDBlock.keyMR}>
                                            <IconContext.Provider value={{ className: 'react-icons' }}>
                                              <RiCheckboxMultipleBlankLine />
                                            </IconContext.Provider>
                                            {fBlock.parentDBlock.keyMR}
                                          </Link>
                                        </span>
                                    ) : 
                                        <Text disabled>N/A</Text>
                                    }
                            </Descriptions.Item>
                        </Descriptions>

                        <Title level={4}>
                          <IconContext.Provider value={{ className: 'react-icons' }}>
                            <RiExchangeLine />
                          </IconContext.Provider>
                          Factoid Transactions
                          <Count count={fBlock.fTxs ? fBlock.fTxs.length : 0} />
                        </Title>

                        <Table
                            dataSource={fBlock.fTxs}
                            columns={columns}
                            pagination={pagination}
                            rowKey="txId"
                            scroll={{ x: 'max-content' }}
                        />

                    </div>
                ) :
                    <div>
                        {error ? (
                            <div class="skeleton-holder">
                                <Alert message={error} type="error" showIcon />
                            </div>
                        ) :
                            <div>
                                <Title level={4}>
                                  <IconContext.Provider value={{ className: 'react-icons' }}>
                                    <RiInformationLine />
                                  </IconContext.Provider>
                                  FBlock Info
                                </Title>
                                <div class="skeleton-holder">
                                    <Skeleton active />
                                </div>
                                <Title level={4}>
                                  <IconContext.Provider value={{ className: 'react-icons' }}>
                                    <RiExchangeLine />
                                  </IconContext.Provider>
                                  Factoid Transactions
                                </Title>
                                <div class="skeleton-holder">
                                    <Skeleton active />
                                </div>
                            </div>
                        }
                    </div>
                }
        </div>
    );
}

export default FBlock;
