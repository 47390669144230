import React, { useState, useEffect } from 'react';
import useInterval from 'use-interval'
import ReactGA from 'react-ga';

import { Link } from 'react-router-dom';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import monoBlue from 'react-syntax-highlighter/dist/esm/styles/hljs/mono-blue';
import { Base64 } from 'js-base64';

import Countdown, { zeroPad } from 'react-countdown';

import {
  Typography,
  Descriptions,
  Skeleton,
  Alert,
  Tooltip,
  Tag,
  Radio,
  Tabs,
  message
} from 'antd';

import { IconContext } from "react-icons";
import {
    RiInformationLine, RiFileList2Line, RiQuestionLine, RiPriceTag3Line, RiLinksLine, RiFileCopy2Line, RiCheckboxCircleLine, RiLoader4Line
} from 'react-icons/ri';

import Moment from 'react-moment';
import axios from 'axios';
import axiosRetry from 'axios-retry';

import { NotifyNetworkError } from './../common/Notifications';
import ExtID from './../common/ExtID';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const Entry = ({ match }) => {

    const [entry, setEntry] = useState(null);
    const [error, setError] = useState(null);

    const defaultType = "ASCII";
    const [type, setType] = useState(defaultType);
    const [jsonDisabled, setJSONDisabled] = useState(true);
    const [isPending, setIsPending] = useState(false);

    const CountdownRenderer = ({ minutes, seconds }) => {
      return <span>{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
    };

    const handleChange = event => {
      setType(event.target.value);
    };

    function IsJsonString(str) {
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
    }

    const getEntry = async (entryHash = entryHash, force = true) => {
      
        if (force) {
          document.title = "Entry " + entryHash + " | Factom Realtime Explorer";
          setIsPending(false);
          setJSONDisabled(true);
          setType(defaultType);
          setEntry(null);
          setError(null);
        }
        try {
            const response = await axios.get('/explorer/entries/'+entryHash);
            setEntry(response.data.result);
            setIsPending(false);
            if (force) {
              if (response.data.result.content) {
                if ([...Base64.decode(response.data.result.content)].some(char => char.charCodeAt(0) < 32 || (char.charCodeAt(0) > 127 && char.charCodeAt(0) < 256))) {
                  setType("Base64");
                } else {
                  if (IsJsonString(Base64.decode(response.data.result.content))) {
                    setJSONDisabled(false);
                    setType("JSON");
                  } else {
                    setType("ASCII");
                  }
                }
              }
            }
        }
        catch(error) {
            if (error.response) {
                if (force) {
                    const pendingRequest = axios.create();
                    const hide = message.loading('Checking pending entries', 0);
                    axiosRetry(pendingRequest, { retries: 5, retryDelay: axiosRetry.exponentialDelay });
                    pendingRequest.get('/explorer/pending_entries/'+entryHash)
                    .then(resp => {
                      setIsPending(true);
                      setEntry(resp.data.result);
                      if (resp.data.result.content) {
                        if ([...Base64.decode(resp.data.result.content)].some(char => char.charCodeAt(0) < 32 || (char.charCodeAt(0) > 127 && char.charCodeAt(0) < 256))) {
                          setType("Base64");
                        } else {
                          if (IsJsonString(Base64.decode(resp.data.result.content))) {
                            setJSONDisabled(false);
                            setType("JSON");
                          } else {
                            setType("ASCII");
                          }
                        }
                      }
                    })
                    .catch(error => {
                      if (error.response) {
                        setError(error.response.data.error);
                      } else {
                        NotifyNetworkError();
                      }
                    })
                    .finally(function() {
                      hide();
                    })
                }
            } else {
                NotifyNetworkError();
            }
        }
    }

    function EntryBlocks(props) {
      const data = props.data;
      const items = data.map((item) =>
        <span>
        <Link to={'/eblocks/' + item.keyMR}>
        <IconContext.Provider value={{ className: 'react-icons' }}>
            <RiFileCopy2Line />
        </IconContext.Provider>
        {item.keyMR}
        </Link>
        <br />
        </span>
      );
      return (
        <span className="code break-all">{items}</span>
      );
    }

    useInterval(() => {
      getEntry(match.params.entryhash, false);
    }, isPending ? 5000 : null);

    useEffect(() => {
      ReactGA.pageview(window.location.pathname);
      getEntry(match.params.entryhash, true);
    }, [match.params.entryhash]);

    return (
        <div>
            <Title level={2}>Entry</Title>
            <Title level={4} type="secondary" style={{ marginTop: "-10px" }} className="break-all" copyable>{match.params.entryhash}</Title>
                {entry ? (
                    <div>
                        <Title level={4}>
                          <IconContext.Provider value={{ className: 'react-icons' }}>
                            <RiInformationLine />
                          </IconContext.Provider>
                          Entry Info
                        </Title>
                        <Descriptions bordered column={1} size="middle">
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The block height of the parent DBlock, which indicates the length of the blockchain."><RiQuestionLine /></Tooltip></IconContext.Provider>Block</nobr> Height</span>}>
                            {entry.parentDBlock ? (
                                  <span className="code">
                                    <Link to={'/dblocks/' + entry.parentDBlock.keyMR}>
                                      {entry.parentDBlock.dbHeight}
                                    </Link>
                                  </span>
                                ) :
                                  <Text disabled>N/A</Text>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The date and time at which an entry is created."><RiQuestionLine /></Tooltip></IconContext.Provider>Timestamp</nobr> <nobr>(UTC+{-(new Date().getTimezoneOffset() / 60)})</nobr></span>}>
                                <span className="code">
                                  {(entry.entryBlocks && (entry.entryBlocks.length>1)) ? (
                                    <Moment unix format="YYYY-MM-DD HH:mm" local>
                                      {entry.entryBlocks[0].timestamp}
                                    </Moment>
                                  ) :
                                    <Moment format="YYYY-MM-DD HH:mm" local>
                                      {entry.createdAt}
                                    </Moment>
                                  }
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The hash of the current Entry."><RiQuestionLine /></Tooltip></IconContext.Provider>Entry</nobr> Hash</span>}>
                                <span className="code break-all">{entry.entryHash}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The status of the current Entry."><RiQuestionLine /></Tooltip></IconContext.Provider>Status</nobr></span>}>
                                {entry.status === "completed" ? (
                                  <Tooltip overlayClassName="explorer-tooltip" title="Added to the Directory Block.">
                                    <Tag color="green" style={{textTransform: "uppercase"}}><IconContext.Provider value={{ className: 'react-icons' }}><RiCheckboxCircleLine /></IconContext.Provider>Confirmed</Tag>
                                  </Tooltip>
                                ) : 
                                  <div>
                                  <Tooltip overlayClassName="explorer-tooltip" title="Will be added to the next Directory Block.">
                                    <Tag color="blue" style={{textTransform: "uppercase"}}><IconContext.Provider value={{ className: 'react-icons' }}><RiLoader4Line className={'anticon-spin'} /></IconContext.Provider>Pending</Tag>
                                  </Tooltip>
                                  <Text type="secondary">≈ <Countdown date={Date.parse(entry.createdAt) + 600000} zeroPadTime={2} renderer={CountdownRenderer} /></Text>
                                  </div>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The hash of the Chain, that the current Entry belongs to."><RiQuestionLine /></Tooltip></IconContext.Provider>Chain</nobr> ID</span>}>
                                <span className="code break-all">
                                    <Link to={'/chains/' + entry.chainId}>
                                        <IconContext.Provider value={{ className: 'react-icons' }}>
                                            <RiLinksLine />
                                        </IconContext.Provider>
                                        {entry.chainId}
                                    </Link>
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The KeyMR of the Entry Block(s), that the current Entry belongs to."><RiQuestionLine /></Tooltip></IconContext.Provider>EBlock(s)</nobr></span>}>
                              {entry.entryBlocks && entry.entryBlocks[0] ? (
                                <EntryBlocks data={entry.entryBlocks} />
                              ) :
                                <Text disabled>N/A</Text>
                              }
                            </Descriptions.Item>
                        </Descriptions>

                        <Title level={4}>
                          <nobr>
                          <IconContext.Provider value={{ className: 'react-icons' }}>
                            <RiPriceTag3Line />
                          </IconContext.Provider>
                          External IDs
                          <IconContext.Provider value={{ className: 'react-icons-tooltip' }}>
                            <Tooltip overlayClassName="explorer-tooltip" title="External IDs (ExtID) are intended to provide any sort of tagging information for Chains and Entries that applications may find useful.">
                              <RiQuestionLine />
                            </Tooltip>
                          </IconContext.Provider>
                          </nobr>
                        </Title>

                        {entry.extIds ? (
                            <div className="extids">
                              {entry.extIds.map((item) => <ExtID>{item}</ExtID>)}
                            </div>
                          ) :
                            <div class="skeleton-holder">
                              <Alert message="No external IDs" type="info" showIcon />
                            </div>
                        }

                        <Title level={4}>
                          <IconContext.Provider value={{ className: 'react-icons' }}>
                            <RiFileList2Line />
                          </IconContext.Provider>
                          Content
                        </Title>

                        {entry.content ? (
                          <div>
                          <Radio.Group defaultValue={defaultType} value={type} buttonStyle="solid" className="type-radio" onChange={handleChange}>
                            <Radio.Button value="ASCII" className="type-ASCII">ASCII</Radio.Button>
                            <Radio.Button value="JSON" className="type-JSON" disabled={jsonDisabled}>JSON</Radio.Button>
                            <Radio.Button value="Base64" className="type-Base64">Base64</Radio.Button>
                            <Radio.Button value="Hex" className="type-Hex">Hex</Radio.Button>
                          </Radio.Group>

                          <Tabs defaultActiveKey={defaultType} activeKey={type} className="entry-content" style={{marginTop: 15}}>
                            <TabPane key="ASCII">
                              <div className="content break-all"><Text copyable>{Base64.decode(entry.content)}</Text></div>
                            </TabPane>
                            <TabPane key="JSON">
                              {!jsonDisabled ? ( <SyntaxHighlighter language="json" style={monoBlue}>{JSON.stringify(JSON.parse(Base64.decode(entry.content)), null, 4)}</SyntaxHighlighter> ) : null}
                            </TabPane>
                            <TabPane key="Base64">
                              <div className="content code break-all"><Text copyable>{entry.content}</Text></div>
                            </TabPane>
                            <TabPane key="Hex">
                              <div className="content code break-all"><Text copyable>{Buffer.from(entry.content, 'base64').toString('hex')}</Text></div>
                            </TabPane>
                          </Tabs>
                          </div>
                        ) :
                          <div class="skeleton-holder">
                            <Alert message="No content" type="info" showIcon />
                          </div>
                        }

                    </div>
                ) :
                    <div>
                        {error ? (
                            <div class="skeleton-holder">
                                <Alert message={error} type="error" showIcon />
                            </div>
                        ) :
                            <div>
                                <Title level={4}>
                                  <IconContext.Provider value={{ className: 'react-icons' }}>
                                    <RiInformationLine />
                                  </IconContext.Provider>
                                  Entry Info
                                </Title>
                                <div class="skeleton-holder">
                                    <Skeleton active />
                                </div>
                                <Title level={4}>
                                  <IconContext.Provider value={{ className: 'react-icons' }}>
                                    <RiPriceTag3Line />
                                  </IconContext.Provider>
                                  External IDs
                                </Title>
                                <div class="skeleton-holder">
                                    <Skeleton active />
                                </div>
                                <Title level={4}>
                                  <IconContext.Provider value={{ className: 'react-icons' }}>
                                    <RiFileList2Line />
                                  </IconContext.Provider>
                                  Content
                                </Title>
                                <div class="skeleton-holder">
                                    <Skeleton active />
                                </div>
                            </div>
                        }
                    </div>
                }
        </div>
    );
}

export default Entry;
