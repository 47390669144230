import React, { useState, useEffect } from 'react';
import {
    Row,
    Col,
    Card,
    Carousel,
    Skeleton,
    Typography
} from 'antd';

import axios from 'axios';

import { IconContext } from "react-icons";
import {
    RiCopperCoinLine, RiFireLine
} from 'react-icons/ri';

const { Title } = Typography;

const StatsFCT = props => {

  const [supply, setSupply] = useState(-1);
  const [burnt, setBurnt] = useState(-1);

  const getStatsFCT = () => {
    axios.get('/explorer/stats')
    .then(function(response) {
        setSupply(response.data.result.supply);
        setBurnt(response.data.result.burnt);
        props.callback(response.data.result.supply);
    });
  }

  useEffect(() => getStatsFCT(), []);

  return (
      <div>
        <Row gutter={[16,16]}>
          <Col xs={0} sm={12} md={8} lg={6} xl={5}>
            <Card>
                <span>
                    <IconContext.Provider value={{ className: 'react-icons react-icons-entries' }}><RiCopperCoinLine /></IconContext.Provider>
                    <br />
                    FCT Circulating Supply
                </span>
                {supply === -1 ? (
                    <Skeleton active paragraph={false} />
                ) : 
                    <div>
                    <Title level={3} className="code">{Math.round(supply/100000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Title>
                    </div>
                }
            </Card>
          </Col>
          <Col xs={0} sm={12} md={8} lg={6} xl={5}>
            <Card>
                <span>
                    <IconContext.Provider value={{ className: 'react-icons' }}><RiFireLine /></IconContext.Provider>
                    <br />
                    FCT Burnt <small>/ all time</small>
                </span>
                {burnt === -1 ? (
                    <Skeleton active paragraph={false} />
                ) : 
                    <div>
                    <Title level={3} className="code">{Math.round(burnt/100000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Title>
                    </div>
                }
            </Card>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={0} md={0} lg={0} xl={0}>
            <Carousel autoplay>
                <Card>
                    <span>
                        <IconContext.Provider value={{ className: 'react-icons react-icons-entries' }}><RiCopperCoinLine /></IconContext.Provider>
                        <br />
                        FCT Circulating Supply
                    </span>
                    {supply === -1 ? (
                        <Skeleton active paragraph={false} />
                    ) : 
                        <div>
                        <Title level={3} className="code">{Math.round(supply/100000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Title>
                        </div>
                    }
                </Card>
                <Card>
                    <span>
                        <IconContext.Provider value={{ className: 'react-icons' }}><RiFireLine /></IconContext.Provider>
                        <br />
                        FCT Burnt <small>/ all time</small>
                    </span>
                    {burnt === -1 ? (
                        <Skeleton active paragraph={false} />
                    ) : 
                        <div>
                        <Title level={3} className="code">{Math.round(burnt/100000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Title>
                        </div>
                    }
                </Card>
            </Carousel>
          </Col>
        </Row>
      </div>
  );

};

export default StatsFCT;
