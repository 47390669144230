import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';

import { Link } from 'react-router-dom';

import axios from 'axios';

import {
  Typography,
  Table,
  message,
  Progress
} from 'antd';

import { IconContext } from "react-icons";
import {
    RiCopperCoinLine
} from 'react-icons/ri';

import { NotifyNetworkError } from './../common/Notifications';
import StatsFCT from './../common/StatsFCT';

const { Title } = Typography;

const Richlist = () => {

  const [richlist, setRichlist] = useState([]);
  const [supply, setSupply] = useState(-1);
  const [tableIsLoading, setTableIsLoading] = useState(true);
  const pagination = {defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '50', '100'], showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`};

  const getRichlist = async () => {
    ReactGA.pageview(window.location.pathname);
    setTableIsLoading(true);
    try {
      const response = await axios.get('/explorer/richlist');
      setRichlist(response.data.result);
    }
    catch(error) {
        if (error.response) {
          message.error(error.response.data.error);
        } else {
          NotifyNetworkError();
        }
    }
    setTableIsLoading(false);
  }

  const columns = [
    {
      title: '#',
      className: 'code',
      width: 30,
      render: (row) => (
        <span>{richlist.indexOf(row)+1}</span>
      )
    },
    {
      title: 'Address',
      dataIndex: 'pubAddress',
      className: 'code',
      render: (pubAddress) => {
        return (
        <Link to={'/addresses/' + pubAddress}>
            <IconContext.Provider value={{ className: 'react-icons' }}>
                <RiCopperCoinLine />
            </IconContext.Provider>
            {pubAddress}
        </Link>
        )
      }
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      className: 'code',
      render: (balance) => (
        <span>{balance/100000000} FCT</span>
      )
    },
    {
      title: '% of circulating supply',
      className: 'code',
      render: (row) => {
        if (supply > 0) {
          return (
            <Progress strokeColor='#52c41a' percent={Math.round(((row.balance + Number.EPSILON)/supply) * 10000) / 100} size="small" />
          )
        }        
      }
    }
  ];

  useEffect(() => {
    document.title = "Rich List | Factom Realtime Explorer";
    getRichlist();
  }, []);

  return (
    <div>

      <div className="stats">
        <StatsFCT callback={setSupply} />
      </div>

      <Title level={2}>Rich List</Title>
      <Title level={4} type="secondary" style={{ marginTop: "-10px" }}>Top 500 Factoid Addresses</Title>

      <Table
        dataSource={richlist}
        columns={columns}
        pagination={pagination}
        rowKey="height"
        loading={tableIsLoading}
        scroll={{ x: 'max-content' }}
      />

    </div>
  );
}

export default Richlist;
