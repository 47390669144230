import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

import {
  Typography,
  List,
  Tag
} from 'antd';

import { IconContext } from "react-icons";
import {
    RiCalendarLine, RiPriceTag3Line
} from 'react-icons/ri';

const { Title, Paragraph } = Typography;

const Changelog = () => {

  useEffect(() => {
    document.title = "Changelog | Factom Realtime Explorer";
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div>
      <Title level={2}>Changelog</Title>
      <List size="large">
        
        <List.Item style={{ justifyContent: 'normal' }}>
          <Typography>
            <Paragraph>
              <Tag color="geekblue">
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiPriceTag3Line />
                </IconContext.Provider>
                0.8.1
              </Tag>
              <Tag>
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiCalendarLine />
                </IconContext.Provider>
                2020-09-28
              </Tag>
            </Paragraph>
            <Paragraph>
              <Title level={4}>Factom Anchors + UX/UI improvements</Title>
              <ul>
                <li>FCT anchors support (for testnet and custom networks)</li>
                <li>Improved clearing of pending transactions</li>
                <li>First entry of chain displayed into Chain view</li>
                <li>Enabled switch between Mainnet and Testnet</li>
              </ul>
            </Paragraph>
          </Typography>
        </List.Item>
        <List.Item style={{ justifyContent: 'normal' }}>
          <Typography>
            <Paragraph>
              <Tag color="geekblue">
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiPriceTag3Line />
                </IconContext.Provider>
                0.8.0
              </Tag>
              <Tag>
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiCalendarLine />
                </IconContext.Provider>
                2020-08-29
              </Tag>
            </Paragraph>
            <Paragraph>
              <Title level={4}>Bitcoin & Ethereum Anchors</Title>
              <ul>
                <li>BTC and ETH anchors displayed into DBlock view</li>
                <li>BTC and ETH anchoring data added into Explorer API calls</li>
                <li>Added heights indicators into footer</li>
              </ul>
            </Paragraph>
          </Typography>
        </List.Item>
        <List.Item style={{ justifyContent: 'normal' }}>
          <Typography>
            <Paragraph>
              <Tag color="geekblue">
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiPriceTag3Line />
                </IconContext.Provider>
                0.7.7
              </Tag>
              <Tag>
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiCalendarLine />
                </IconContext.Provider>
                2020-08-18
              </Tag>
            </Paragraph>
            <Paragraph>
              <Title level={4}>Factoid Stats + optimization</Title>
              <ul>
                <li>Added FCT circulating supply and burn counters in the Rich List</li>
                <li>Improved FCT transactions parsing</li>
                <li>Improved FCT balance calculation</li>
                <li>Implemented caching for stats and FCT Rich List</li>
              </ul>
            </Paragraph>
          </Typography>
      </List.Item>
      <List.Item style={{ justifyContent: 'normal' }}>
          <Typography>
            <Paragraph>
              <Tag color="geekblue">
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiPriceTag3Line />
                </IconContext.Provider>
                0.7.6
              </Tag>
              <Tag>
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiCalendarLine />
                </IconContext.Provider>
                2020-08-10
              </Tag>
            </Paragraph>
            <Paragraph>
              <Title level={4}>Factoid Rich List</Title>
              <ul>
                <li>Added FCT Rich List</li>
              </ul>
            </Paragraph>
          </Typography>
      </List.Item>
      <List.Item style={{ justifyContent: 'normal' }}>
          <Typography>
            <Paragraph>
              <Tag color="geekblue">
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiPriceTag3Line />
                </IconContext.Provider>
                0.7.5
              </Tag>
              <Tag>
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiCalendarLine />
                </IconContext.Provider>
                2020-06-30
              </Tag>
            </Paragraph>
            <Paragraph>
              <Title level={4}>UX/UI improvements</Title>
              <ul>
                <li>Added empty ExtIDs support</li>
                <li>Minor UX improvements of the search form</li>
              </ul>
            </Paragraph>
          </Typography>
      </List.Item>
      <List.Item style={{ justifyContent: 'normal' }}>
          <Typography>
            <Paragraph>
              <Tag color="geekblue">
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiPriceTag3Line />
                </IconContext.Provider>
                0.7.4
              </Tag>
              <Tag>
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiCalendarLine />
                </IconContext.Provider>
                2020-06-24
              </Tag>
            </Paragraph>
            <Paragraph>
              <Title level={4}>Latest pending transactions and entries</Title>
              <ul>
                <li>Latest transactions and entries displayed on frontpage</li>
                <li>A special section has been added in which all pending records and transactions are displayed and updated in real time</li>
              </ul>
            </Paragraph>
          </Typography>
      </List.Item>
      <List.Item style={{ justifyContent: 'normal' }}>
          <Typography>
            <Paragraph>
              <Tag color="geekblue">
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiPriceTag3Line />
                </IconContext.Provider>
                0.7.3
              </Tag>
              <Tag>
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiCalendarLine />
                </IconContext.Provider>
                2020-06-22
              </Tag>
            </Paragraph>
            <Paragraph>
              <Title level={4}>Optimization update</Title>
              <ul>
                <li>Optimized balance calculation for FA addresses</li>
                <li>Improved database clearing algorithm for pending entries and transactions</li>
              </ul>
            </Paragraph>
          </Typography>
      </List.Item>
      <List.Item style={{ justifyContent: 'normal' }}>
          <Typography>
            <Paragraph>
              <Tag color="geekblue">
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiPriceTag3Line />
                </IconContext.Provider>
                0.7.2
              </Tag>
              <Tag>
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiCalendarLine />
                </IconContext.Provider>
                2020-06-18
              </Tag>
            </Paragraph>
            <Paragraph>
              <Title level={4}>Realtime factoid transactions</Title>
              <ul>
                <li>Implemented realtime factoid transactions display</li>
                <li>Added pending factoid transactions API</li>
              </ul>
            </Paragraph>
          </Typography>
      </List.Item>
      <List.Item style={{ justifyContent: 'normal' }}>
          <Typography>
            <Paragraph>
              <Tag color="geekblue">
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiPriceTag3Line />
                </IconContext.Provider>
                0.7.1
              </Tag>
              <Tag>
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiCalendarLine />
                </IconContext.Provider>
                2020-06-17
              </Tag>
            </Paragraph>
            <Paragraph>
              <Title level={4}>Realtime chains</Title>
              <ul>
                <li>Implemented realtime chains display</li>
                <li>Added pending chains API</li>
              </ul>
            </Paragraph>
          </Typography>
      </List.Item>
      <List.Item style={{ justifyContent: 'normal' }}>
          <Typography>
            <Paragraph>
              <Tag color="geekblue">
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiPriceTag3Line />
                </IconContext.Provider>
                0.7.0
              </Tag>
              <Tag>
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiCalendarLine />
                </IconContext.Provider>
                2020-06-16
              </Tag>
            </Paragraph>
            <Paragraph>
              <Title level={4}>Realtime entries</Title>
              <ul>
                <li>Implemented realtime entries display</li>
                <li>Added pending entries API</li>
              </ul>
            </Paragraph>
          </Typography>
      </List.Item>
      <List.Item style={{ justifyContent: 'normal' }}>
          <Typography>
            <Paragraph>
              <Tag color="geekblue">
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiPriceTag3Line />
                </IconContext.Provider>
                0.6.5
              </Tag>
              <Tag>
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiCalendarLine />
                </IconContext.Provider>
                2020-06-15
              </Tag>
            </Paragraph>
            <Paragraph>
              <Title level={4}>Data display improvements</Title>
              <ul>
                <li>Shown chains external IDs in DBlock view</li>
                <li>Improved blockchain stats on the frontpage</li>
                <li>UX/UI improvement</li>
                <li>Minor bug fix</li>
              </ul>
            </Paragraph>
          </Typography>
      </List.Item>
      <List.Item style={{ justifyContent: 'normal' }}>
          <Typography>
            <Paragraph>
              <Tag color="geekblue">
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiPriceTag3Line />
                </IconContext.Provider>
                0.6.4
              </Tag>
              <Tag>
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiCalendarLine />
                </IconContext.Provider>
                2020-06-10
              </Tag>
            </Paragraph>
            <Paragraph>
              <Title level={4}>Bug fix</Title>
              <ul>
                <li>Search query params are used in chain search by external IDs, so you can share search results</li>
                <li>Fixed Factoid transactions</li>
                <li>Fixed Google Analytics</li>
              </ul>
            </Paragraph>
          </Typography>
        </List.Item>
        <List.Item style={{ justifyContent: 'normal' }}>
          <Typography>
            <Paragraph>
              <Tag color="geekblue">
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiPriceTag3Line />
                </IconContext.Provider>
                0.6.3
              </Tag>
              <Tag>
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiCalendarLine />
                </IconContext.Provider>
                2020-06-07
              </Tag>
            </Paragraph>
            <Paragraph>
              <Title level={4}>New functions</Title>
              <ul>
                <li>Added search/filtering chains by external IDs</li>
                <li>External IDs in tables are now clickable — switch between data format (ASCII, Base64, Hex) by click</li>
                <li>Added blockchain stats on the frontpage</li>
              </ul>
            </Paragraph>
          </Typography>
        </List.Item>
        <List.Item style={{ justifyContent: 'normal' }}>
          <Typography>
            <Paragraph>
              <Tag color="geekblue">
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiPriceTag3Line />
                </IconContext.Provider>
                0.6.2
              </Tag>
              <Tag>
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiCalendarLine />
                </IconContext.Provider>
                2020-06-04
              </Tag>
            </Paragraph>
            <Paragraph>
              <Title level={4}>Data display improvements</Title>
              <ul>
                <li>Added Entry Blocks in Chain view</li>
                <li>If entry is included into multiple Entry Blocks, they all shown in Entry view</li>
                <li>Added Coinbase txs and updated filters</li>
                <li>Improved display of External IDs</li>
              </ul>
            </Paragraph>
          </Typography>
        </List.Item>
        <List.Item style={{ justifyContent: 'normal' }}>
          <Typography>
            <Paragraph>
              <Tag color="geekblue">
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiPriceTag3Line />
                </IconContext.Provider>
                0.6.1
              </Tag>
              <Tag>
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiCalendarLine />
                </IconContext.Provider>
                2020-06-03
              </Tag>
            </Paragraph>
            <Paragraph>
              <Title level={4}>Usability update</Title>
              <ul>
                <li>Optimized reading entries from large chains</li>
                <li>UX/UI improvement</li>
              </ul>
            </Paragraph>
          </Typography>
        </List.Item>
        <List.Item style={{ justifyContent: 'normal' }}>
          <Typography>
            <Paragraph>
              <Tag color="geekblue">
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiPriceTag3Line />
                </IconContext.Provider>
                0.6.0
              </Tag>
              <Tag>
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiCalendarLine />
                </IconContext.Provider>
                2020-05-29
              </Tag>
            </Paragraph>
            <Paragraph>
              <Title level={4}>Performance update</Title>
              <ul>
                <li>Database and response time optimization</li>
                <li>Fixed relations between DBlocks and ECBlocks in range of heights 22880&ndash;70410</li>
              </ul>
            </Paragraph>
          </Typography>
        </List.Item>
        <List.Item style={{ justifyContent: 'normal' }}>
          <Typography>
            <Paragraph>
              <Tag color="geekblue">
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiPriceTag3Line />
                </IconContext.Provider>
                0.5.0
              </Tag>
              <Tag>
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  <RiCalendarLine />
                </IconContext.Provider>
                2020-05-25
              </Tag>
            </Paragraph>
            <Paragraph>
              <Title level={4}>Private alpha release</Title>
              <ul>
                <li>Blocks
                  <ul>
                    <li>Directory Block (DBlock)</li>
                    <li>Admin Block (ABlock)</li>
                    <li>Factoid Block (FBlock)</li>
                    <li>Entry Block (EBlock)</li>
                    <li>Entry Credit (ECBlock)</li>
                  </ul>
                </li>
                <li>Chains
                  <ul>
                    <li>Chain</li>
                    <li>Entry</li>
                  </ul>
                </li>
                <li>Addresses
                  <ul>
                    <li>Factoid Address</li>
                    <li>EC Address</li>
                  </ul>
                </li>
                <li>Search</li>
              </ul>
            </Paragraph>
          </Typography>
        </List.Item>
      </List>
    </div>
  );

};

export default Changelog;
