import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';

import { Link } from 'react-router-dom';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import monoBlue from 'react-syntax-highlighter/dist/esm/styles/hljs/mono-blue';
import { Base64 } from 'js-base64';

import {
  Typography,
  Descriptions,
  Skeleton,
  Alert,
  List,
  Tooltip
} from 'antd';

import { IconContext } from "react-icons";
import {
    RiCheckboxMultipleBlankLine, RiInformationLine, RiFileList2Line, RiQuestionLine, RiAccountBoxLine
} from 'react-icons/ri';

import Moment from 'react-moment';
import axios from 'axios';

import { NotifyNetworkError } from './../common/Notifications';
import Count from './../common/Count';

const { Title, Text } = Typography;

const ABlock = ({ match }) => {

    SyntaxHighlighter.registerLanguage('javascript', json);

    const [height, setHeight] = useState(null);
    const [aBlock, setABlock] = useState(null);
    const [aEntries, setAEntries] = useState(null);
    const [error, setError] = useState(null);

    const getABlock = async (keyMR = keyMR) => {
        ReactGA.pageview(window.location.pathname);
        document.title = "ABlock " + keyMR + " | Factom Realtime Explorer";
        setABlock(null);
        setError(null);
        try {
            const response = await axios.get('/explorer/ablocks/'+keyMR);
            setABlock(response.data.result);
            setHeight(response.data.result.dbHeight);
            if (response.data.result.abEntries) {
              const entries = JSON.parse(Base64.decode(response.data.result.abEntries));
              if (entries) {
                setAEntries(entries);
              } else {
                setAEntries("");
              }
            }
        }
        catch(error) {
            setABlock(null);
            if (error.response) {
                setError(error.response.data.error);
            } else {
                NotifyNetworkError();
            }
        }
    }

    const prevABlock = () => {
      getABlock(aBlock.prevABlock.lookupHash);
      setHeight(height-1);
    }

    const nextABlock = () => {
      getABlock(aBlock.nextABlock.lookupHash);
      setHeight(height+1);
    }

    useEffect(() => {
        getABlock(match.params.keymr);
    }, [match.params.keymr]);

    return (
        <div>
            <Title level={2}>Admin Block {height!==null ? "#"+height : null}</Title>
            <Title level={4} type="secondary" style={{ marginTop: "-10px" }} className="break-all" copyable>{match.params.keymr}</Title>
                {aBlock ? (
                    <div>
                        <Title level={4}>
                          <IconContext.Provider value={{ className: 'react-icons' }}>
                            <RiInformationLine />
                          </IconContext.Provider>
                          ABlock Info
                        </Title>
                        <Descriptions bordered column={1} size="middle">
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The block height of the parent DBlock, which indicates the length of the blockchain."><RiQuestionLine /></Tooltip></IconContext.Provider>Block</nobr> Height</span>}>
                                {aBlock.parentDBlock ? (
                                  <span className="code">
                                    <Link to={'/dblocks/' + aBlock.parentDBlock.keyMR}>
                                      {aBlock.dbHeight}
                                    </Link>
                                  </span>
                                ) :
                                  <span className="code">
                                    {aBlock.dbHeight}
                                  </span>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The sequence number of the current Admin Block."><RiQuestionLine /></Tooltip></IconContext.Provider>Sequence</nobr> Number</span>}>
                                  <span className="code">
                                    {aBlock.dbHeight}
                                  </span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The date and time at which a block is created."><RiQuestionLine /></Tooltip></IconContext.Provider>Timestamp</nobr> <nobr>(UTC+{-(new Date().getTimezoneOffset() / 60)})</nobr></span>}>
                                {aBlock.parentDBlock ? (
                                  <span className="code"><Moment unix format="YYYY-MM-DD HH:mm" local>{aBlock.parentDBlock.timestamp*60}</Moment></span>
                                ) :
                                  <Text disabled>N/A</Text>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The hash of the current Admin Block."><RiQuestionLine /></Tooltip></IconContext.Provider>Lookup</nobr> Hash</span>}>
                                <span className="code break-all">{aBlock.lookupHash}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The hash of the next Admin Block."><RiQuestionLine /></Tooltip></IconContext.Provider>Next</nobr> ABlock</span>}>
                                    {aBlock.nextABlock ? (
                                        <span className="code break-all">
                                            <Link to={'/ablocks/' + aBlock.nextABlock.lookupHash} onClick={nextABlock}>
                                                <IconContext.Provider value={{ className: 'react-icons' }}>
                                                    <RiAccountBoxLine />
                                                </IconContext.Provider>
                                                {aBlock.nextABlock.lookupHash}
                                            </Link>
                                        </span>
                                    ) : 
                                        <Text disabled>Not available yet</Text>
                                    }
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The hash of the previous Admin Block."><RiQuestionLine /></Tooltip></IconContext.Provider>Previous</nobr> ABlock</span>}>
                                <span className="code break-all">
                                    {aBlock.dbHeight > 0 ? (
                                        <Link to={'/ablocks/' + aBlock.prevABlock.lookupHash} onClick={prevABlock}>
                                            <IconContext.Provider value={{ className: 'react-icons' }}>
                                                <RiAccountBoxLine />
                                            </IconContext.Provider>
                                            {aBlock.prevABlock.lookupHash}
                                        </Link>
                                    ) : 
                                        <span>
                                            0000000000000000000000000000000000000000000000000000000000000000
                                        </span>
                                    }
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The hash of the Directory Block, that contains the current Admin Block."><RiQuestionLine /></Tooltip></IconContext.Provider>Parent</nobr> DBlock</span>}>
                                    {aBlock.parentDBlock ? (
                                        <span className="code break-all">
                                          <Link to={'/dblocks/' + aBlock.parentDBlock.keyMR}>
                                            <IconContext.Provider value={{ className: 'react-icons' }}>
                                              <RiCheckboxMultipleBlankLine />
                                            </IconContext.Provider>
                                            {aBlock.parentDBlock.keyMR}
                                          </Link>
                                        </span>
                                    ) : 
                                        <Text disabled>N/A</Text>
                                    }
                            </Descriptions.Item>
                        </Descriptions>

                        <Title level={4}>
                          <IconContext.Provider value={{ className: 'react-icons' }}>
                            <RiFileList2Line />
                          </IconContext.Provider>
                          Admin Entries
                          <Count count={aEntries ? aEntries.length : 0} />
                        </Title>

                        {aEntries!==null ? (
                          <div>
                            {aEntries.length>0 ? (
                              <List
                                size="large"
                                dataSource={aEntries}
                                renderItem={item => <List.Item><SyntaxHighlighter language="json" style={monoBlue}>{JSON.stringify(item, null, 4)}</SyntaxHighlighter></List.Item>}
                              />
                            ) :
                              <div class="skeleton-holder">
                                <Alert message="No admin entries in this admin block" type="info" showIcon />
                              </div>
                            }
                          </div>
                        ) :
                          <div class="skeleton-holder">
                            <Skeleton active />
                          </div>
                        }

                    </div>
                ) :
                    <div>
                        {error ? (
                            <div class="skeleton-holder">
                                <Alert message={error} type="error" showIcon />
                            </div>
                        ) :
                            <div>
                                <Title level={4}>
                                  <IconContext.Provider value={{ className: 'react-icons' }}>
                                    <RiInformationLine />
                                  </IconContext.Provider>
                                  ABlock Info
                                </Title>
                                <div class="skeleton-holder">
                                    <Skeleton active />
                                </div>
                                <Title level={4}>
                                  <IconContext.Provider value={{ className: 'react-icons' }}>
                                    <RiFileList2Line />
                                  </IconContext.Provider>
                                  Admin Entries
                                </Title>
                                <div class="skeleton-holder">
                                    <Skeleton active />
                                </div>
                            </div>
                        }
                    </div>
                }
        </div>
    );
}

export default ABlock;
