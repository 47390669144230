import React, { useState, useEffect, useRef } from 'react';
import ReactGA from 'react-ga';

import { Link, useHistory } from 'react-router-dom';

import Moment from 'react-moment';
import axios from 'axios';
import { Base64 } from 'js-base64';

import {
  Typography,
  Table,
  message,
  Tag,
  Input,
  Space,
  Button
} from 'antd';

import { SearchOutlined } from '@ant-design/icons';

import { IconContext } from "react-icons";
import {
    RiLinksLine
} from 'react-icons/ri';

import { NotifyNetworkError } from './../common/Notifications';
import ExtID from './../common/ExtID';

const { Title } = Typography;

const Chains = ({ location }) => {

  const [chains, setChains] = useState([]);
  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [pagination, setPagination] = useState({pageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '50', '100'], current: 1});
  const searchExtIds = useRef(null);
  const [extIds, setExtIds] = useState(() => {
    let searchParams = new URLSearchParams(location.search);
    if (searchParams.has("q") && searchParams.get("q") !== "") {
      return [searchParams.get("q")];
    }
    return [];
  });
  const history = useHistory();

  const getChains = async (params = pagination) => {
    history.push('/chains');
    document.title = "Chains | Factom Realtime Explorer";
    setTableIsLoading(true);
    try {
      const response = await axios.get('/explorer/chains', { params: { start: (params.current-1)*params.pageSize, limit: params.pageSize } });
      setChains(response.data.result.Items);
      setPagination({...pagination, current: (response.data.start/response.data.limit)+1, pageSize: response.data.limit, total: response.data.total, showTotal: (total, range) => `${(params.current-1)*params.pageSize+1}-${Math.min(response.data.total, params.current*params.pageSize)} of ${response.data.total}`});
    }
    catch(error) {
        if (error.response) {
          message.error(error.response.data.error);
        } else {
          NotifyNetworkError();
        }
    }
    setTableIsLoading(false);
  }

  const searchChains = async (params = pagination) => {
    let searchParams = new URLSearchParams();
    searchParams.append('q', extIds[0]);
    history.push('/chains?'+searchParams.toString());
    document.title = "Chains "+extIds[0]+" | Factom Realtime Explorer";
    setTableIsLoading(true);
    try {
      let ext = [];
      ext.push(Base64.encode(extIds));
      const response = await axios.post('/explorer/chains/search', { extIds: ext }, { params: { start: (params.current-1)*params.pageSize, limit: params.pageSize } });
      setChains(response.data.result.Items);
      setPagination({...pagination, current: (response.data.start/response.data.limit)+1, pageSize: response.data.limit, total: response.data.total, showTotal: (total, range) => `${(params.current-1)*params.pageSize+1}-${Math.min(response.data.total, params.current*params.pageSize)} of ${response.data.total}`});
    }
    catch(error) {
        if (error.response) {
          message.error(error.response.data.error);
        } else {
          NotifyNetworkError();
        }
    }
    setTableIsLoading(false);
  }

  const handleChange = (params = pagination) => {
    if (extIds.length > 0) {
      searchChains(params);
    } else {
      getChains(params);
    }
  }

  const columns = [
    {
      title: 'Timestamp (UTC+'+ -(new Date().getTimezoneOffset() / 60) + ')',
      dataIndex: 'createdAt',
      className: 'code',
      width: 30,
      render: (createdAt) => (
        <nobr><Moment format="YYYY-MM-DD HH:mm" local>{createdAt}</Moment></nobr>
      )
    },
    {
      title: 'Chain ID',
      dataIndex: 'chainId',
      className: 'code',
      render: (chainId) => (
        <Link to={'/chains/' + chainId}>
          <IconContext.Provider value={{ className: 'react-icons' }}>
            <RiLinksLine />
          </IconContext.Provider>
          {chainId}
        </Link>
      )
    },
    {
      title: 'External IDs',
      dataIndex: 'extIds',
      className: 'filter-extid',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchExtIds}
            placeholder={`Search by ExtID`}
            value={selectedKeys[0]}
            onChange={e => { setSelectedKeys(e.target.value ? [e.target.value] : []); setExtIds(e.target.value ? [e.target.value] : []); }}
            onPressEnter={() => { confirm(); }}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => { confirm(); }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 188 }}
            >
              Search
            </Button>
          </Space>
        </div>
        )
      },
      filterIcon: filtered => {
        return (
          <SearchOutlined style={{ backgroundColor: filtered ? '#5f26dd' : '#aaa' }} />
        )
      },
      onFilter: (record) => {
        return record;
      },
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchExtIds.current.select());
        }
      },
      defaultFilteredValue: (extIds.length>0 ? extIds : null),
      render: (extIds) => {
        if (extIds !== null) {
          var items = extIds.slice(0,3).map((item) => <ExtID compact>{item}</ExtID>);
          let extra = extIds.length-3;
          if (extra > 0) {
            items.push(<Tag className="extid-tag">+{extra} more</Tag>);
          }
          return <nobr>{items}</nobr>;
        } else {
          return <div></div>;
        }
      },
    },
  ];

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    let searchParams = new URLSearchParams(location.search);
    if (searchParams.has("q") && searchParams.get("q") !== "") {
      document.title = "Chains "+searchParams.get("q")+" | Factom Realtime Explorer";
      searchChains();
    } else {
      document.title = "Chains | Factom Realtime Explorer";
      getChains();
    }
  }, []);

  return (
    <div>
      <Title level={2}>Chains</Title>
      <Table
        dataSource={chains}
        columns={columns}
        pagination={pagination}
        rowKey="chainId"
        loading={tableIsLoading}
        onChange={handleChange}
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
}

export default Chains;
