import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';

import { Link } from 'react-router-dom';
import { Base64 } from 'js-base64';

import {
  Typography,
  Descriptions,
  message,
  Skeleton,
  Alert,
  Table,
  Tooltip,
  Tag,
  Tabs,
  Radio
} from 'antd';

import { IconContext } from "react-icons";
import {
    RiCheckboxMultipleBlankLine, RiLinksLine, RiInformationLine, RiStarLine, RiFileCopy2Line, RiQuestionLine, RiAccountBoxLine, RiExchangeBoxLine, RiCheckboxLine, RiAnchorLine, RiExternalLinkLine
} from 'react-icons/ri';

import Moment from 'react-moment';
import axios from 'axios';

import { NotifyNetworkError } from './../common/Notifications';
import Count from './../common/Count';
import ExtID from './../common/ExtID';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const DBlock = props => {

    const [height, setHeight] = useState(null);
    const [totalEBlocks, setTotalEBlocks] = useState(-1);
    const [dBlock, setDBlock] = useState(null);
    const [eBlocks, setEBlocks] = useState(null);
    const [tableIsLoading, setTableIsLoading] = useState(true);
    const [pagination, setPagination] = useState({pageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '50', '100'], current: 1});
    const [error, setError] = useState(null);

    const [btcAnchor, setBTCAnchor] = useState(null);
    const [ethAnchor, setETHAnchor] = useState(null);
    const [fctAnchor, setFCTAnchor] = useState(null);

    const [showBTCAnchor, setShowBTCAnchor] = useState(false);
    const [showETHAnchor, setShowETHAnchor] = useState(false);
    const [showFCTAnchor, setShowFCTAnchor] = useState(false);

    const anchorDefaultSelectedTab = "tx";
    const [btcAnchorSelectedTab, setBTCAnchorSelectedTab] = useState(anchorDefaultSelectedTab);
    const [ethAnchorSelectedTab, setETHAnchorSelectedTab] = useState(anchorDefaultSelectedTab);
    const [fctAnchorSelectedTab, setFCTAnchorSelectedTab] = useState(anchorDefaultSelectedTab);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const toggleBTCAnchor = () => {
        const visible = showBTCAnchor === false ? true : false;
        setShowBTCAnchor(visible);
    };

    const toggleETHAnchor = () => {
        const visible = showETHAnchor === false ? true : false;
        setShowETHAnchor(visible);
    };

    const toggleFCTAnchor = () => {
        const visible = showFCTAnchor === false ? true : false;
        setShowETHAnchor(visible);
    };

    const handleBTCAnchorTabChange = event => {
        setBTCAnchorSelectedTab(event.target.value);
    };

    const handleETHAnchorTabChange = event => {
        setETHAnchorSelectedTab(event.target.value);
    };

    const handleFCTAnchorTabChange = event => {
        setFCTAnchorSelectedTab(event.target.value);
    };

    const getDBlock = async (keyMR = keyMR) => {
        ReactGA.pageview(window.location.pathname);
        document.title = "DBlock " + keyMR + " | Factom Realtime Explorer";
        source.cancel();
        setDBlock(null);
        setEBlocks(null);
        setTotalEBlocks(-1);
        setError(null);
        setPagination({...pagination, current: 1});
        setShowBTCAnchor(false);
        setShowETHAnchor(false);
        setShowFCTAnchor(false);
        try {
            const response = await axios.get('/explorer/dblocks/'+keyMR);
            setDBlock(response.data.result);
            setHeight(response.data.result.dbHeight);
            if (response.data.result.anchors) {
                setBTCAnchor(response.data.result.anchors.find(a => a.blockchain === "bitcoin"));
                setETHAnchor(response.data.result.anchors.find(a => a.blockchain === "ethereum"));
                setFCTAnchor(response.data.result.anchors.find(a => a.blockchain === "factom"));
            }
        }
        catch(error) {
            if (error.response) {
                setError(error.response.data.error);
            } else {
                NotifyNetworkError();
            }
        }
    }

    const getEBlocks = async (params = pagination) => {
        setTableIsLoading(true);

        let start = 0;
        let limit = 10;
        let showTotalStart = 1;
        let showTotalFinish = 10;

        if (params) {
            start = (params.current-1)*params.pageSize;
            limit = params.pageSize;
            showTotalStart = (params.current-1)*params.pageSize+1;
            showTotalFinish = params.current*params.pageSize;
        }

        try {
          const response = await axios.get('/explorer/eblocks', { params: { start: start, limit: limit, height: dBlock.dbHeight }, cancelToken: source.token } );
          setEBlocks(response.data.result.Items);
          setPagination({...pagination, current: (response.data.start/response.data.limit)+1, pageSize: response.data.limit, total: response.data.total, showTotal: (total, range) => `${showTotalStart}-${Math.min(response.data.total, showTotalFinish)} of ${response.data.total}`});
          setTotalEBlocks(response.data.total);
        }
        catch(error) {
            if (error.response) {
                if (error.response.data.error) {
                    message.error(error.response.data.error);
                }
              } else {
                if (!axios.isCancel(error)) {
                    NotifyNetworkError();
                }
            }
        }
        setTableIsLoading(false);
    }
    
    const columns = [
        {
            title: 'EBlock KeyMR',
            dataIndex: 'keyMR',
            className: 'code',
            render: (keyMR) => (
                <Link to={'/eblocks/' + keyMR}>
                    <IconContext.Provider value={{ className: 'react-icons' }}>
                        <RiFileCopy2Line />
                    </IconContext.Provider>
                    {keyMR}
                </Link>
            )
        },
        {
            title: 'Chain ID',
            dataIndex: 'chainId',
            className: 'code',
            render: (chainID) => (
                <Link to={'/chains/' + chainID}>
                    <IconContext.Provider value={{ className: 'react-icons' }}>
                        <RiLinksLine />
                    </IconContext.Provider>
                    {chainID}
                </Link>
            )
        },
        {
            title: 'Entries',
            dataIndex: 'entriesCount',
            className: 'code',
            align: 'center'
        },
        {
            title: 'Chain External IDs',
            dataIndex: 'extIds',
            render: (extIds) => {
              if (extIds !== null) {
                var items = extIds.slice(0,3).map((item) => <ExtID compact>{item}</ExtID>);
                let extra = extIds.length-3;
                if (extra > 0) {
                  items.push(<Tag className="extid-tag">+{extra} more</Tag>);
                }
                return <nobr>{items}</nobr>;
              } else {
                return null;
              }
            }
        },
    ];

    const prevDBlock = () => {
        getDBlock(dBlock.prevKeyMR);
        setHeight(height-1);
    }

    const nextDBlock = () => {
        getDBlock(dBlock.nextDBlock.keyMR);
        setHeight(height+1);
    }

    useEffect(() => {
        if (dBlock) {
            getEBlocks();
        }
    }, [dBlock]);

    useEffect(() => {
        getDBlock(props.match.params.keymr);
    }, [props.match.params.keymr]);

    useEffect(() => {
        // returned function will be called on component unmount 
        return () => {
          source.cancel();
        }
    }, []);

    return (
        <div>
            <Title level={2}>Directory Block {height!==null ? "#"+height : null}</Title>
            <Title level={4} type="secondary" style={{ marginTop: "-10px" }} className="break-all" copyable>{props.match.params.keymr}</Title>
                {dBlock ? (
                    <div>
                        <Title level={4}>
                            <IconContext.Provider value={{ className: 'react-icons' }}>
                                <RiInformationLine />
                            </IconContext.Provider>
                            DBlock Info
                        </Title>
                        <Descriptions bordered column={1} size="middle">
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="Also known as Block Number. The block height, which indicates the length of the blockchain, increases after the addition of the new block."><RiQuestionLine /></Tooltip></IconContext.Provider>Block</nobr> Height</span>}>
                                <span className="code">{dBlock.dbHeight}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The date and time at which a block is created."><RiQuestionLine /></Tooltip></IconContext.Provider>Timestamp</nobr> <nobr>(UTC+{-(new Date().getTimezoneOffset() / 60)})</nobr></span>}>
                                <span className="code"><Moment unix format="YYYY-MM-DD HH:mm" local>{dBlock.timestamp*60}</Moment></span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="Also known as Key Merkle Root. The hash of the current Directory Block."><RiQuestionLine /></Tooltip></IconContext.Provider>KeyMR</nobr></span>}>
                                <span className="code break-all">
                                    {dBlock.keyMR}
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The hash of the next Directory Block."><RiQuestionLine /></Tooltip></IconContext.Provider>Next</nobr> DBlock</span>}>
                                    {dBlock.nextDBlock ? (
                                        <span className="code break-all">
                                            <Link to={'/dblocks/' + dBlock.nextDBlock.keyMR} onClick={nextDBlock}>
                                                <IconContext.Provider value={{ className: 'react-icons' }}>
                                                    <RiCheckboxMultipleBlankLine />
                                                </IconContext.Provider>
                                                {dBlock.nextDBlock.keyMR}
                                            </Link>
                                        </span>
                                    ) : 
                                        <Text disabled>Not available yet</Text>
                                    }
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The hash of the previous Directory Block."><RiQuestionLine /></Tooltip></IconContext.Provider>Previous</nobr> DBlock</span>}>
                                <span className="code break-all">
                                    {dBlock.prevKeyMR !== "0000000000000000000000000000000000000000000000000000000000000000" ? (
                                        <Link to={'/dblocks/' + dBlock.prevKeyMR} onClick={prevDBlock}>
                                            <IconContext.Provider value={{ className: 'react-icons' }}>
                                                <RiCheckboxMultipleBlankLine />
                                            </IconContext.Provider>
                                            {dBlock.prevKeyMR}
                                        </Link>
                                    ) : 
                                        <span>
                                            {dBlock.prevKeyMR}
                                        </span>
                                    }
                                </span>
                            </Descriptions.Item>
                            {props.anchorsHeight && props.anchorsHeight.btc ? (
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="Bitcoin transaction in which Directory Block hash is written via OP_RETURNs."><RiQuestionLine /></Tooltip></IconContext.Provider>Bitcoin</nobr> Anchor</span>}>
                                <span className="break-all">
                                    {btcAnchor ? (
                                        <span>
                                            <a onClick={() => toggleBTCAnchor()} className="dotted code">
                                                <IconContext.Provider value={{ className: 'react-icons' }}>
                                                    <RiAnchorLine />
                                                </IconContext.Provider>
                                                {btcAnchor.txID}
                                            </a>
                                            {showBTCAnchor ? (
                                                <div className="anchor-info">
                                                    <Radio.Group defaultValue={anchorDefaultSelectedTab} value={btcAnchorSelectedTab} buttonStyle="solid" onChange={handleBTCAnchorTabChange}>
                                                        <Radio.Button value="tx">Anchor TX</Radio.Button>
                                                        <Radio.Button value="json">JSON</Radio.Button>
                                                    </Radio.Group>
                                                    <Tabs defaultActiveKey={anchorDefaultSelectedTab} activeKey={btcAnchorSelectedTab} style={{marginTop: 10}}>
                                                        <TabPane key="tx">
                                                            TXID: <a href={"https://live.blockcypher.com/btc/tx/"+btcAnchor.txID} class="code" target="_blank">
                                                                {btcAnchor.txID}
                                                                <IconContext.Provider value={{ className: 'react-icons react-icons-end' }}>
                                                                    <RiExternalLinkLine />
                                                                </IconContext.Provider>
                                                            </a>
                                                            <br />
                                                            Block: <a href={"https://live.blockcypher.com/btc/block/"+btcAnchor.blockHash} class="code" target="_blank">
                                                                {btcAnchor.blockHash}
                                                                <IconContext.Provider value={{ className: 'react-icons react-icons-end' }}>
                                                                    <RiExternalLinkLine />
                                                                </IconContext.Provider>
                                                            </a>
                                                        </TabPane>
                                                        <TabPane key="json">
                                                            <div class="content"><Text copyable>{JSON.stringify(JSON.parse(Base64.decode(btcAnchor.anchorRecord)), null, 4)}</Text></div>
                                                        </TabPane>
                                                    </Tabs>
                                                </div>
                                            ) :
                                                null
                                            }
                                        </span>
                                    ) : 
                                        <Text disabled>N/A</Text>
                                    }
                                </span>
                            </Descriptions.Item>
                            ) :
                                null
                            }
                            {props.anchorsHeight && props.anchorsHeight.eth ? (
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="Ethereum transaction in which Directory Block hash is written."><RiQuestionLine /></Tooltip></IconContext.Provider>Ethereum</nobr> Anchor</span>}>
                                <span className="break-all">
                                    {ethAnchor ? (
                                        <span>
                                            <a onClick={() => toggleETHAnchor()} className="dotted code">
                                                <IconContext.Provider value={{ className: 'react-icons' }}>
                                                    <RiAnchorLine />
                                                </IconContext.Provider>
                                                {ethAnchor.txID}
                                            </a>
                                            {showETHAnchor ? (
                                                <div className="anchor-info">
                                                    <Radio.Group defaultValue={anchorDefaultSelectedTab} value={ethAnchorSelectedTab} buttonStyle="solid" onChange={handleETHAnchorTabChange}>
                                                        <Radio.Button value="tx">Anchor TX</Radio.Button>
                                                        <Radio.Button value="json">JSON</Radio.Button>
                                                    </Radio.Group>
                                                    <Tabs defaultActiveKey={anchorDefaultSelectedTab} activeKey={ethAnchorSelectedTab} style={{marginTop: 10}}>
                                                        <TabPane key="tx">
                                                            TXID: <a href={"https://blockchair.com/ethereum/transaction/"+ethAnchor.txID} class="code" target="_blank">
                                                                {ethAnchor.txID}
                                                                <IconContext.Provider value={{ className: 'react-icons react-icons-end' }}>
                                                                    <RiExternalLinkLine />
                                                                </IconContext.Provider>
                                                            </a>
                                                            <br />
                                                            Block: <a href={"https://blockchair.com/ethereum/block/"+ethAnchor.blockHash} class="code" target="_blank">
                                                                {ethAnchor.blockHash}
                                                                <IconContext.Provider value={{ className: 'react-icons react-icons-end' }}>
                                                                    <RiExternalLinkLine />
                                                                </IconContext.Provider>
                                                            </a>
                                                        </TabPane>
                                                        <TabPane key="json">
                                                            <div class="content"><Text copyable>{JSON.stringify(JSON.parse(Base64.decode(ethAnchor.anchorRecord)), null, 4)}</Text></div>
                                                        </TabPane>
                                                    </Tabs>
                                                </div>
                                            ) :
                                                null
                                            }
                                        </span>
                                    ) : 
                                        <Text disabled>N/A</Text>
                                    }
                                </span>
                            </Descriptions.Item>
                            ) :
                                null
                            }
                            {props.anchorsHeight && props.anchorsHeight.fct ? (
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="Factom mainnet transaction in which Directory Block hash is written."><RiQuestionLine /></Tooltip></IconContext.Provider>Factom</nobr> Anchor</span>}>
                                <span className="break-all">
                                    {fctAnchor ? (
                                        <span>
                                            <a onClick={() => toggleFCTAnchor()} className="dotted code">
                                                <IconContext.Provider value={{ className: 'react-icons' }}>
                                                    <RiAnchorLine />
                                                </IconContext.Provider>
                                                {fctAnchor.txID}
                                            </a>
                                            {showFCTAnchor ? (
                                                <div className="anchor-info">
                                                    <Radio.Group defaultValue={anchorDefaultSelectedTab} value={fctAnchorSelectedTab} buttonStyle="solid" onChange={handleFCTAnchorTabChange}>
                                                        <Radio.Button value="tx">Anchor TX</Radio.Button>
                                                        <Radio.Button value="json">JSON</Radio.Button>
                                                    </Radio.Group>
                                                    <Tabs defaultActiveKey={anchorDefaultSelectedTab} activeKey={fctAnchorSelectedTab} style={{marginTop: 10}}>
                                                        <TabPane key="tx">
                                                            TXID: <a href={"https://explorer.factom.pro/entries/"+fctAnchor.txID} class="code" target="_blank">
                                                                {fctAnchor.txID}
                                                                <IconContext.Provider value={{ className: 'react-icons react-icons-end' }}>
                                                                    <RiExternalLinkLine />
                                                                </IconContext.Provider>
                                                            </a>
                                                            <br />
                                                            Block: <a href={"https://explorer.factom.pro/dblocks/"+fctAnchor.blockHash} class="code" target="_blank">
                                                                {fctAnchor.blockHash}
                                                                <IconContext.Provider value={{ className: 'react-icons react-icons-end' }}>
                                                                    <RiExternalLinkLine />
                                                                </IconContext.Provider>
                                                            </a>
                                                        </TabPane>
                                                        <TabPane key="json">
                                                            <div class="content"><Text copyable>{JSON.stringify(JSON.parse(Base64.decode(fctAnchor.anchorRecord)), null, 4)}</Text></div>
                                                        </TabPane>
                                                    </Tabs>
                                                </div>
                                            ) :
                                                null
                                            }
                                        </span>
                                    ) : 
                                        <Text disabled>N/A</Text>
                                    }
                                </span>
                            </Descriptions.Item>
                            ) :
                                null
                            }
                        </Descriptions>
                        <Title level={4}>
                            <IconContext.Provider value={{ className: 'react-icons' }}>
                                <RiStarLine />
                            </IconContext.Provider>
                            Special Blocks
                        </Title>
                        <Descriptions bordered column={1} size="middle">
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="Also known as ABlock. An Administrative Block that records metadata about the network."><RiQuestionLine /></Tooltip></IconContext.Provider>Admin</nobr> Block</span>}>
                                <span className="code break-all">
                                    {dBlock.aBlock ? (
                                        <Link to={'/ablocks/' + dBlock.aBlock.lookupHash}>
                                            <IconContext.Provider value={{ className: 'react-icons' }}>
                                                <RiAccountBoxLine />
                                            </IconContext.Provider>
                                            {dBlock.aBlock.lookupHash}
                                        </Link>
                                    ) : 
                                        <Text disabled>N/A</Text>
                                    }
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="Also known as ECBlock. Holds transactions that create chains and entries."><RiQuestionLine /></Tooltip></IconContext.Provider>Entry</nobr> Credit Block</span>}>
                                <span className="code break-all">
                                    {dBlock.ecBlock ? (
                                        <Link to={'/ecblocks/' + dBlock.ecBlock.headerHash}>
                                            <IconContext.Provider value={{ className: 'react-icons' }}>
                                                <RiCheckboxLine />
                                            </IconContext.Provider>
                                            {dBlock.ecBlock.headerHash}
                                        </Link>
                                    ) : 
                                        <Text disabled>N/A</Text>
                                    }
                                </span>
                            </Descriptions.Item>
                                <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="Also known as FBlock. Holds transactions of FCT and EC tokens."><RiQuestionLine /></Tooltip></IconContext.Provider>Factoid</nobr> Block</span>}>
                            <span className="code break-all">
                                    {dBlock.fBlock ? (
                                        <Link to={'/fblocks/' + dBlock.fBlock.keyMR}>
                                            <IconContext.Provider value={{ className: 'react-icons' }}>
                                                <RiExchangeBoxLine />
                                            </IconContext.Provider>
                                            {dBlock.fBlock.keyMR}
                                        </Link>
                                    ) : 
                                        <Text disabled>N/A</Text>
                                    }
                                </span>
                            </Descriptions.Item>
                        </Descriptions>

                        <Title level={4}>
                            <IconContext.Provider value={{ className: 'react-icons react-icons-eblocks' }}>
                                <RiFileCopy2Line />
                            </IconContext.Provider>
                            Entry Blocks
                            <Count count={totalEBlocks} />
                        </Title>

                        <Table
                            dataSource={eBlocks}
                            columns={columns}
                            pagination={pagination}
                            rowKey="keyMR"
                            loading={tableIsLoading}
                            onChange={getEBlocks}
                            scroll={{ x: 'max-content' }}
                        />

                    </div>
                ) :
                    <div>
                        {error ? (
                            <div class="skeleton-holder">
                                <Alert message={error} type="error" showIcon />
                            </div>
                        ) :
                            <div>
                                <Title level={4}>
                                    <IconContext.Provider value={{ className: 'react-icons' }}>
                                        <RiInformationLine />
                                    </IconContext.Provider>
                                    DBlock Info
                                </Title>
                                <div class="skeleton-holder">
                                    <Skeleton active />
                                </div>
                                <Title level={4}>
                                    <IconContext.Provider value={{ className: 'react-icons' }}>
                                        <RiStarLine />
                                    </IconContext.Provider>
                                    Special Blocks
                                </Title>
                                <div class="skeleton-holder">
                                    <Skeleton active />
                                </div>
                                <Title level={4}>
                                    <IconContext.Provider value={{ className: 'react-icons react-icons-eblocks' }}>
                                        <RiFileCopy2Line />
                                    </IconContext.Provider>
                                    Entry Blocks
                                    <Count count={totalEBlocks} />
                                </Title>
                                <div class="skeleton-holder">
                                    <Skeleton active />
                                </div>
                            </div>
                        }
                    </div>
                }
        </div>
    );
}

export default DBlock;
