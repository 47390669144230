import React, { useState, useEffect, useRef } from 'react';
import { Router, Route, Link, Switch } from 'react-router-dom';

import { Menu, Layout, Input, Badge, Divider, Select, Typography, message, Form, Tooltip, Dropdown, Button } from 'antd';

import { isValidAddress } from 'factom';

import { IconContext } from "react-icons";
import {
    RiCheckboxMultipleBlankLine, RiLinksLine, RiVipDiamondLine, RiServerLine, RiCodeSSlashLine, RiAnchorLine, RiExternalLinkLine
} from 'react-icons/ri';

import {
    LoadingOutlined, DownOutlined
} from '@ant-design/icons';

import axios from 'axios';
import Moment from 'react-moment';

import Logo from './common/Logo';
import ScrollToTop from './common/ScrollToTop';
import History from './common/History';

import { NotifyNetworkError } from './common/Notifications';

import DBlocks from './explorer/DBlocks';
import Chains from './explorer/Chains';
import Changelog from './explorer/Changelog';
import Error404 from './explorer/Error404';

import DBlock from './explorer/DBlock';
import EBlock from './explorer/EBlock';
import ABlock from './explorer/ABlock';
import FBlock from './explorer/FBlock';
import ECBlock from './explorer/ECBlock';
import Chain from './explorer/Chain';
import Entry from './explorer/Entry';
import Address from './explorer/Address';
import Transaction from './explorer/Transaction';
import Richlist from './explorer/Richlist';
import Pending from './explorer/Pending';

const { Header, Content, Footer } = Layout;
const { Search } = Input;
const { Option } = Select;
const { Text } = Typography;

const Version = "0.8.1";

const Explorer = props => {
    
  const [currentMenu, setCurrentMenu] = useState([window.location.pathname]);
  const [info, setInfo] = useState(null);
  const [searchIsLoading, setSearchIsLoading] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [searchForm] = Form.useForm();
  const [anchorsHeight, setAnchorsHeight] = useState(null);

  const handleMenuClick = e => {
    if (e.key === "logo") {
        setCurrentMenu("/dblocks");
    } else {
        setCurrentMenu([e.key]);
    }
  };

  const handleChangelogClick = () => {
    setCurrentMenu(null);
  };

  const handleSearch = (value) => {
    setSearchIsLoading(true);
    var isnum = /^\d+$/.test(value);
    var ishash = /\b[0-9A-Fa-f]{64}\b/.test(value);
    if (isnum && Number.parseInt(value) >= 0) {
        getDBlockByHeight(Number.parseInt(value));
    }
    else if (ishash) {
        getTypeByHash(value);
    }
    else if (isValidAddress(value)) {
        setSearchIsLoading(false);
        setRedirect('/addresses/'+value);
    }
    else {
        setSearchIsLoading(false);
        message.info('No results found');
    }
  };

  const getDBlockByHeight = async (height) => {
    try {
        const response = await axios.get('/explorer/dblocks/by_height/'+height);
        setRedirect('/dblocks/'+response.data.result.keyMR);
    }
    catch(error) {
        if (error.response) {
            message.error(error.response.data.error);
        } else {
            NotifyNetworkError();
        }
    }
    setSearchIsLoading(false);
}

  const getTypeByHash = async (hash) => {
    try {
        const response = await axios.post('/explorer/search/', { hash: hash });
        setRedirect('/'+response.data.result+'/'+hash);
    }
    catch(error) {
        if (error.response) {
            message.error(error.response.data.error);
        } else {
            NotifyNetworkError();
        }
    }
    setSearchIsLoading(false);
  }

  const getInfo = async () => {
    try {
        const response = await axios.get('/explorer');
        setInfo(response.data.result);
        if (response.data.result.anchorsHeight) {
            setAnchorsHeight(response.data.result.anchorsHeight);
        }
    }
    catch(error) {
        if (error.response) {
            message.error(error.response.data.error);
        } else {
            NotifyNetworkError();
        }
    }
  }

  const ExplorerSelect = (
    <Menu>
      <Menu.Item>
        {info && info.name === "mainnet" ? (
            <Badge status="success" text="Factom Mainnet" />
        ) :
            <a target="_blank" rel="noopener noreferrer" href="https://explorer.factom.pro">
                <Badge status="success" text="Factom Mainnet" />
            </a>
        }
      </Menu.Item>
      <Menu.Item>
        {info && info.name === "testnet" ? (
            <Badge status="success" text="Factom Testnet" />
        ) :
            <a target="_blank" rel="noopener noreferrer" href="https://testnet.explorer.factom.pro">
                <Badge status="success" text="Factom Testnet" />
            </a>
        }
      </Menu.Item>
    </Menu>
  );

  const ExplorerSelectFooter = (
    <Menu>
      <Menu.Item>
        {info && info.name === "mainnet" ? (
            <Text>Mainnet</Text>
        ) :
            <a target="_blank" rel="noopener noreferrer" href="https://explorer.factom.pro">
                Mainnet
            </a>
        }
      </Menu.Item>
      <Menu.Item>
        {info && info.name === "testnet" ? (
            <Text>Testnet</Text>
        ) :
            <a target="_blank" rel="noopener noreferrer" href="https://testnet.explorer.factom.pro">
                Testnet
            </a>
        }
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (redirect) {
        History.push(redirect);
        searchForm.resetFields();
        setRedirect(null);
    }
  }, [redirect]);

  useEffect(() => {
    getInfo();
    if (window.location.pathname === "/" || window.location.pathname.includes("blocks")) {
        setCurrentMenu("/dblocks");
    }
    if (window.location.pathname.includes("chains")) {
        setCurrentMenu("/chains");
    }
  }, []);

  useInterval(() => {
    getInfo();
  }, 60000);

  return (
    <Router history={History}>
    <ScrollToTop />
      <Layout>
        <Layout>
        <Header style={{ padding: 0, margin: 0 }}>
            <Menu theme="dark" mode="horizontal" onClick={handleMenuClick} selectedKeys={currentMenu}>
                <Menu.Item key="logo">
                    <Link to="/">
                        <Logo />
                    </Link>
                </Menu.Item>
                <Menu.Item key="/dblocks">
                    <Link to="/">
                        <IconContext.Provider value={{ className: 'react-icons' }}><RiCheckboxMultipleBlankLine /></IconContext.Provider>
                        <span className="nav-text">Blocks</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/chains">
                    <Link to="/chains">
                    <IconContext.Provider value={{ className: 'react-icons react-icons-chains' }}><RiLinksLine /></IconContext.Provider>
                        <span className="nav-text">Chains</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key="/richlist">
                    <Link to="/richlist">
                    <IconContext.Provider value={{ className: 'react-icons' }}><RiVipDiamondLine /></IconContext.Provider>
                        <span className="nav-text">Rich List</span>
                    </Link>
                </Menu.Item>
            </Menu>
            {(info && (info.name === "mainnet" || info.name === "testnet")) ? (
                <Dropdown overlay={ExplorerSelect} trigger={['click']} className="network-badge">
                    <Button ghost>
                        {info.name === "mainnet" ? (
                            <Badge status="success" text="Factom Mainnet" />
                        ) :
                            <Badge status="success" text="Factom Testnet" />
                        }
                        <DownOutlined />
                    </Button>
                </Dropdown>
            ) :
                null
            }
        </Header>
        <Content style={{ padding: 20, margin: 0 }}>
            <Form form={searchForm} initialValues={{ search: '' }}>
                <Form.Item name="search" style={{ marginBottom: 0 }}>
                    <Search
                        placeholder="Search by height, hash, address"
                        size="large"
                        enterButton
                        onSearch={(value) => { if (value!=='') { handleSearch(value); } }}
                        loading={searchIsLoading}
                        spellCheck={false}
                        autoComplete="off"
                        disabled={searchIsLoading}
                    />
                </Form.Item>
            </Form>
            <div class="featured">
                <a href="https://otc.de-facto.pro" target="_blank">
                    <strong>🏦  Factom OTC Desk<IconContext.Provider value={{ className: 'react-icons react-icons-end' }}><RiExternalLinkLine /></IconContext.Provider></strong>
                </a><br />
                Buy or sell FCT. Operated by De Facto — one of the most reliable Factom ANOs.
            </div>
        </Content>
        <Content style={{ padding: '0 20px 30px 20px', margin: 0 }}>
            <Switch>
                <Route exact path="/" component={DBlocks} />
                <Route exact path="/dblocks" component={DBlocks} />
                <Route path="/dblocks/:keymr" render={(props) => <DBlock anchorsHeight={anchorsHeight} {...props} />} />
                <Route exact path="/chains" component={Chains} />
                <Route path="/chains/:chainid" component={Chain} />
                <Route path="/eblocks/:keymr" component={EBlock} />
                <Route path="/fblocks/:keymr" component={FBlock} />
                <Route path="/ablocks/:keymr" component={ABlock} />
                <Route path="/ecblocks/:keymr" component={ECBlock} />
                <Route path="/entries/:entryhash" component={Entry} />
                <Route path="/addresses/:address" component={Address} />
                <Route path="/transactions/:txid" component={Transaction} />
                <Route exact path="/richlist" component={Richlist} />
                <Route exact path="/pending" component={Pending} />
                <Route exact path="/changelog" component={Changelog} />
                <Route component={Error404} />
            </Switch>
        </Content>
        <Footer>
            © <Moment format="YYYY" local/> Factom Realtime Explorer
            <br />
            {(info && (info.name === "mainnet" || info.name === "testnet")) ? (
                <span>
                <Dropdown overlay={ExplorerSelectFooter} trigger={['click']} placement="topLeft">
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        {info.name === "mainnet" ? (
                            <span>Mainnet</span>
                        ) :
                            <span>Testnet</span>
                        }
                        <DownOutlined style={{ marginLeft: 5 }} />
                    </a>
                </Dropdown>
                <Divider type="vertical" />
                </span>
            ) :
                null
            }
            UI: {Version}
            <Divider type="vertical" />
            API: {info ? info.version : <LoadingOutlined style={{ marginLeft: 5, marginRight: 5 }} />}
            <Divider type="vertical" />
            <Link to="/changelog" onClick={handleChangelogClick}>
                Changelog
            </Link>
            {info ? (
                <div className="heights">
                    <div className="heights-item">
                        <IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="Explorer height"><RiCodeSSlashLine /></Tooltip></IconContext.Provider>
                        <span className="code">{info.apiHeight}</span>
                    </div>
                    <div className="heights-item">
                        <IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="Node height"><RiServerLine /></Tooltip></IconContext.Provider>
                        <span className="code">{info.blockchainHeight}</span>
                    </div>
                    {info.anchorsHeight && info.anchorsHeight.btc ? (
                        <div className="heights-item">
                            <IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="Latest Bitcoin Anchor"><RiAnchorLine /></Tooltip></IconContext.Provider>
                            BTC: <span className="code">{info.anchorsHeight.btc}</span>
                        </div>
                    ) :
                        null
                    }
                    {info.anchorsHeight && info.anchorsHeight.eth ? (
                    <div className="heights-item">
                        <IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="Latest Ethereum anchor"><RiAnchorLine /></Tooltip></IconContext.Provider>
                        ETH: <span className="code">{info.anchorsHeight.eth}</span>
                    </div>
                    ) :
                        null
                    }
                    {info.anchorsHeight && info.anchorsHeight.fct ? (
                    <div className="heights-item">
                        <IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="Latest Factom anchor"><RiAnchorLine /></Tooltip></IconContext.Provider>
                        FCT: <span className="code">{info.anchorsHeight.fct}</span>
                    </div>
                    ) :
                        null
                    }
                </div>
            ) :
            null
            }
            </Footer>
        </Layout>
      </Layout>
    </Router>
  );
};

function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }
  

export default Explorer;
