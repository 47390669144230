import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';

import { Link } from 'react-router-dom';

import {
  Typography,
  Descriptions,
  Skeleton,
  Alert,
  Table,
  Tooltip,
  message,
  Tag
} from 'antd';

import { IconContext } from "react-icons";
import {
    RiCheckboxMultipleBlankLine, RiInformationLine, RiFileList2Line, RiLinksLine, RiQuestionLine, RiFileCopy2Line
} from 'react-icons/ri';

import Moment from 'react-moment';
import axios from 'axios';

import { NotifyNetworkError } from './../common/Notifications';
import Count from './../common/Count';
import ExtID from './../common/ExtID';

const { Title, Text } = Typography;

const EBlock = ({ match }) => {

    const [eBlock, setEBlock] = useState(null);
    const [tableIsLoading, setTableIsLoading] = useState(true);
    const [entries, setEntries] = useState(null);
    const [totalEntries, setTotalEntries] = useState(-1);
    const [pagination, setPagination] = useState({pageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '50', '100'], current: 1});
    const [error, setError] = useState(null);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const getEBlock = async (keyMR = keyMR) => {
        ReactGA.pageview(window.location.pathname);
        document.title = "EBlock " + keyMR + " | Factom Realtime Explorer";
        source.cancel();
        setEBlock(null);
        setEntries(null);
        setTotalEntries(-1);
        setError(null);
        setPagination({...pagination, current: 1});
        try {
            const response = await axios.get('/explorer/eblocks/'+keyMR);
            setEBlock(response.data.result);
        }
        catch(error) {
            if (error.response) {
                setError(error.response.data.error);
            } else {
                NotifyNetworkError();
            }
        }
    }

    const getEntries = async (params = pagination, filters, sorter) => {

        setTableIsLoading(true);

        let start = 0;
        let limit = 10;
        let showTotalStart = 1;
        let showTotalFinish = 10;
        let sort = "asc";

        if (params) {
            start = (params.current-1)*params.pageSize;
            limit = params.pageSize;
            showTotalStart = (params.current-1)*params.pageSize+1;
            showTotalFinish = params.current*params.pageSize;
        }

        if (sorter) {
            switch (sorter.order) {
                case 'ascend':
                    sort = "asc";
                    break;
                case 'descend':
                    sort = "desc";
                    break;
            }
        }

        try {
          const response = await axios.get('/explorer/eblocks/'+eBlock.keyMR+'/entries', { params: { start: start, limit: limit, sort: sort }, cancelToken: source.token } );
          setEntries(response.data.result);
          setPagination({...pagination, current: (response.data.start/response.data.limit)+1, pageSize: response.data.limit, total: response.data.total, showTotal: (total, range) => `${showTotalStart}-${Math.min(response.data.total, showTotalFinish)} of ${response.data.total}`});
          setTotalEntries(response.data.total);
        }
        catch(error) {
            if (error.response) {
                if (error.response.data.error) {
                    message.error(error.response.data.error);
                }
              } else {
                if (!axios.isCancel(error)) {
                    NotifyNetworkError();
                }
            }
        }
        setTableIsLoading(false);
    }

    const columns = [
        {
            title: 'Timestamp (UTC+'+ -(new Date().getTimezoneOffset() / 60) + ')',
            dataIndex: 'createdAt',
            className: 'code',
            sorter: true,
            width: 30,
            render: (createdAt) => (
              <nobr><Moment format="YYYY-MM-DD HH:mm" local>{createdAt}</Moment></nobr>
            )
        },
        {
            title: 'Entry Hash',
            dataIndex: 'entryHash',
            className: 'code',
            render: (entryHash) => (
                <Link to={'/entries/' + entryHash}>
                    <IconContext.Provider value={{ className: 'react-icons' }}>
                        <RiFileList2Line />
                    </IconContext.Provider>
                    {entryHash}
                </Link>
            )
        },
        {
            title: 'External IDs',
            dataIndex: 'extIds',
            render: (extIds) => {
                if (extIds !== null) {
                var items = extIds.slice(0,3).map((item) => <ExtID compact>{item}</ExtID>);
                let extra = extIds.length-3;
                if (extra > 0) {
                    items.push(<Tag className="extid-tag">+{extra} more</Tag>);
                }
                return <nobr>{items}</nobr>;
                } else {
                return <div></div>;
                }
            }
        },        
    ];

    const prevEBlock = () => {
        getEBlock(eBlock.prevKeyMR);
    }

    const nextEBlock = () => {
        getEBlock(eBlock.nextEBlock.keyMR);
    }

    useEffect(() => {
        if (eBlock) {
            getEntries();
        }
    }, [eBlock]);

    useEffect(() => {
      getEBlock(match.params.keymr);
    }, [match.params.keymr]);

    useEffect(() => {
        // returned function will be called on component unmount 
        return () => {
          source.cancel();
        }
    }, []);
  
    return (
        <div>
            <Title level={2}>Entry Block</Title>
            <Title level={4} type="secondary" style={{ marginTop: "-10px" }} className="break-all" copyable>{match.params.keymr}</Title>
                {eBlock ? (
                    <div>
                        <Title level={4}>
                          <IconContext.Provider value={{ className: 'react-icons' }}>
                            <RiInformationLine />
                          </IconContext.Provider>
                          EBlock Info
                        </Title>
                        <Descriptions bordered column={1} size="middle">
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The block height of the parent DBlock, which indicates the length of the blockchain."><RiQuestionLine /></Tooltip></IconContext.Provider>Block</nobr> Height</span>}>
                                {eBlock.parentDBlock ? (
                                  <span className="code">
                                    <Link to={'/dblocks/' + eBlock.parentDBlock.keyMR}>
                                      {eBlock.dbHeight}
                                    </Link>
                                  </span>
                                ) :
                                  <span className="code">
                                    {eBlock.dbHeight}
                                  </span>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The sequence number of the current Entry Block."><RiQuestionLine /></Tooltip></IconContext.Provider>Sequence</nobr> Number</span>}>
                                  <span className="code">
                                    {eBlock.blockSequenceNumber}
                                  </span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The date and time at which a block is created."><RiQuestionLine /></Tooltip></IconContext.Provider>Timestamp</nobr> <nobr>(UTC+{-(new Date().getTimezoneOffset() / 60)})</nobr></span>}>
                                {eBlock.parentDBlock ? (
                                    <span className="code"><Moment unix format="YYYY-MM-DD HH:mm" local>{eBlock.parentDBlock.timestamp*60}</Moment></span>
                                ) :
                                    <Text disabled>N/A</Text>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="Also known as Key Merkle Root. The hash of the current Entry Block."><RiQuestionLine /></Tooltip></IconContext.Provider>KeyMR</nobr></span>}>
                                <span className="code break-all">{eBlock.keyMR}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The hash of the next Entry Block of the Chain."><RiQuestionLine /></Tooltip></IconContext.Provider>Next</nobr> EBlock</span>}>
                                    {eBlock.nextEBlock ? (
                                        <span className="code break-all">
                                            <Link to={'/eblocks/' + eBlock.nextEBlock.keyMR} onClick={nextEBlock}>
                                                <IconContext.Provider value={{ className: 'react-icons' }}>
                                                    <RiFileCopy2Line />
                                                </IconContext.Provider>
                                                {eBlock.nextEBlock.keyMR}
                                            </Link>
                                        </span>
                                    ) : 
                                        <Text disabled>Not available yet</Text>
                                    }
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The hash of the previous Entry Block of the Chain."><RiQuestionLine /></Tooltip></IconContext.Provider>Previous</nobr> EBlock</span>}>
                                <span className="code break-all">
                                    {eBlock.prevKeyMR !== "0000000000000000000000000000000000000000000000000000000000000000" ? (
                                        <Link to={'/eblocks/' + eBlock.prevKeyMR} onClick={prevEBlock}>
                                            <IconContext.Provider value={{ className: 'react-icons' }}>
                                                <RiFileCopy2Line />
                                            </IconContext.Provider>
                                            {eBlock.prevKeyMR}
                                        </Link>
                                    ) : 
                                        <span>
                                            {eBlock.prevKeyMR}
                                        </span>
                                    }
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The hash of the chain, that the current Entry Block belongs to."><RiQuestionLine /></Tooltip></IconContext.Provider>Chain</nobr> ID</span>}>
                                <span className="code break-all">
                                    <Link to={'/chains/' + eBlock.chainId}>
                                        <IconContext.Provider value={{ className: 'react-icons' }}>
                                            <RiLinksLine />
                                        </IconContext.Provider>
                                        {eBlock.chainId}
                                    </Link>
                                </span>
                            </Descriptions.Item>                            
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The hash of the Directory Block, that contains the current Entry Block."><RiQuestionLine /></Tooltip></IconContext.Provider>Parent</nobr> DBlock</span>}>
                                    {eBlock.parentDBlock ? (
                                        <span className="code break-all">
                                          <Link to={'/dblocks/' + eBlock.parentDBlock.keyMR}>
                                            <IconContext.Provider value={{ className: 'react-icons' }}>
                                              <RiCheckboxMultipleBlankLine />
                                            </IconContext.Provider>
                                            {eBlock.parentDBlock.keyMR}
                                          </Link>
                                        </span>
                                    ) : 
                                        <Text disabled>N/A</Text>
                                    }
                            </Descriptions.Item>
                        </Descriptions>

                        <Title level={4}>
                          <IconContext.Provider value={{ className: 'react-icons' }}>
                            <RiFileList2Line />
                          </IconContext.Provider>
                          Entries
                          <Count count={totalEntries ? totalEntries : 0} />
                        </Title>

                        <Table
                            dataSource={entries}
                            columns={columns}
                            pagination={pagination}
                            rowKey="entryHash"
                            loading={tableIsLoading}
                            onChange={getEntries}
                            scroll={{ x: 'max-content' }}
                        />

                    </div>
                ) :
                    <div>
                        {error ? (
                            <div class="skeleton-holder">
                                <Alert message={error} type="error" showIcon />
                            </div>
                        ) :
                            <div>
                                <Title level={4}>
                                  <IconContext.Provider value={{ className: 'react-icons' }}>
                                    <RiInformationLine />
                                  </IconContext.Provider>
                                  EBlock Info
                                </Title>
                                <div class="skeleton-holder">
                                    <Skeleton active />
                                </div>
                                <Title level={4}>
                                  <IconContext.Provider value={{ className: 'react-icons' }}>
                                    <RiFileList2Line />
                                  </IconContext.Provider>
                                  Entries
                                </Title>
                                <div class="skeleton-holder">
                                    <Skeleton active />
                                </div>
                            </div>
                        }
                    </div>
                }
        </div>
    );
}

export default EBlock;
