import React, { useState, useEffect, useRef } from 'react';
import ReactGA from 'react-ga';

import { Link } from 'react-router-dom';
import { keyToPublicEcAddress } from 'factom';
import { SearchOutlined } from '@ant-design/icons';
import { Base64 } from 'js-base64';

import {
  Typography,
  Descriptions,
  Skeleton,
  Alert,
  Table,
  Tag,
  Input,
  Space,
  Button,
  Tooltip
} from 'antd';

import { IconContext } from "react-icons";
import {
    RiCheckboxMultipleBlankLine, RiInformationLine, RiFileList2Line, RiCouponLine, RiQuestionLine
} from 'react-icons/ri';

import Moment from 'react-moment';
import axios from 'axios';

import { NotifyNetworkError } from './../common/Notifications';
import Count from './../common/Count';

const { Title, Text } = Typography;

const ECBlock = ({ match }) => {

    const pagination = {defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '50', '100'], showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`};
    const [height, setHeight] = useState(null);
    const [ecBlock, setECBlock] = useState(null);
    const [ecTxs, setECTxs] = useState(null);
    const [error, setError] = useState(null);
    const searchEntryHash = useRef(null);
    const searchEC = useRef(null);

    const getECBlock = async (keyMR = keyMR) => {
        ReactGA.pageview(window.location.pathname);
        document.title = "ECBlock " + keyMR + " | Factom Realtime Explorer";
        setECBlock(null);
        setECTxs("");
        setError(null);
        try {
            const response = await axios.get('/explorer/ecblocks/'+keyMR);
            setECBlock(response.data.result);
            setHeight(response.data.result.dbHeight);
            if (response.data.result.entries) {
              const entries = JSON.parse(Base64.decode(response.data.result.entries)).filter(item => item.sig);
              if (entries) {
                setECTxs(entries);
              } else {
                setECTxs("");
              }
            }
        }
        catch(error) {
            if (error.response) {
                setError(error.response.data.error);
            } else {
                NotifyNetworkError();
            }
        }
    }

    const columns = [
      {
        title: 'Timestamp (UTC+'+ -(new Date().getTimezoneOffset() / 60) + ')',
        dataIndex: 'millitime',
        className: 'code',
        width: 30,
        sorter: (a, b) => new Date(a.millitime) - new Date(b.millitime),
        render: (millitime) => (
          <nobr><Moment unix format="YYYY-MM-DD HH:mm" local>{millitime/1000}</Moment></nobr>
        )
      },
      {
        title: 'Commit Type',
        dataIndex: 'chainidhash',
        width: 30,
        filters: [
          { text: 'Chain', value: 'chain' },
          { text: 'Entry', value: 'entry' },
        ],
        onFilter: (value, item) => {
          if (value === "entry") {
            return typeof(item.chainidhash) === "undefined";
          } else {
            return typeof(item.chainidhash) === "string";            
          }
        },
        render: (chainidhash) => {
          if (chainidhash) {
            return (
              <Tag color="green" style={{textTransform: "uppercase"}}>
                Chain
              </Tag>
            )
          } else {
            return (
              <Tag color="blue" style={{textTransform: "uppercase"}}>
                Entry
              </Tag>
            )
          }
        }
      },
      {
        title: 'EC Cost',
        dataIndex: 'credits',
        className: 'code',
        width: 30,
        sorter: (a, b) => a.credits - b.credits,
        render: (credits) => (
          <span>{credits} EC</span>
        )
      },
      {
        title: 'Entry Hash',
        dataIndex: 'entryhash',
        className: 'code filter-entryhash',
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={searchEntryHash}
              placeholder={`Search Entry Hash`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => confirm()}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => confirm()}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ backgroundColor: filtered ? '#5f26dd' : '#aaa' }} />,
        onFilter: (value, record) =>
          record['entryhash'].includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => searchEntryHash.current.select());
          }
        }
      },
      {
        title: 'Paying Address',
        dataIndex: 'ecpubkey',
        className: 'code filter-paying-address',
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={searchEC}
              placeholder={`Search Address`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => confirm()}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => confirm()}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ backgroundColor: filtered ? '#5f26dd' : '#aaa' }} />,
        onFilter: (value, record) =>
          keyToPublicEcAddress(record['ecpubkey']).includes(value),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => searchEC.current.select());
          }
        },
        render: ecpubkey => {
          let ecaddress = keyToPublicEcAddress(ecpubkey);
          return (
            <Link to={'/addresses/' + ecaddress}>
              <IconContext.Provider value={{ className: 'react-icons' }}>
                <RiCouponLine />
              </IconContext.Provider>
              {ecaddress}
            </Link>
            )
          }
        }
    ];

    useEffect(() => {
      getECBlock(match.params.keymr);
    }, [match.params.keymr]);

    return (
        <div>
            <Title level={2}>Entry Credit Block {height!==null ? "#"+height : null}</Title>
            <Title level={4} type="secondary" style={{ marginTop: "-10px" }} className="break-all" copyable>{match.params.keymr}</Title>
                {ecBlock ? (
                    <div>
                        <Title level={4}>
                          <IconContext.Provider value={{ className: 'react-icons' }}>
                            <RiInformationLine />
                          </IconContext.Provider>
                          ECBlock Info
                        </Title>
                        <Descriptions bordered column={1} size="middle">
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The block height of the parent DBlock, which indicates the length of the blockchain."><RiQuestionLine /></Tooltip></IconContext.Provider>Block</nobr> Height</span>}>
                                {ecBlock.parentDBlock ? (
                                  <span className="code">
                                    <Link to={'/dblocks/' + ecBlock.parentDBlock.keyMR}>
                                      {ecBlock.parentDBlock.dbHeight}
                                    </Link>
                                  </span>
                                ) :
                                  <span className="code">
                                    {ecBlock.parentDBlock.dbHeight}
                                  </span>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The sequence number of the current Entry Credit Block."><RiQuestionLine /></Tooltip></IconContext.Provider>Sequence</nobr> Number</span>}>
                                  <span className="code">
                                    {ecBlock.dbHeight}
                                  </span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The date and time at which a block is created."><RiQuestionLine /></Tooltip></IconContext.Provider>Timestamp</nobr> <nobr>(UTC+{-(new Date().getTimezoneOffset() / 60)})</nobr></span>}>
                                {ecBlock.parentDBlock ? (
                                  <span className="code"><Moment unix format="YYYY-MM-DD HH:mm" local>{ecBlock.parentDBlock.timestamp*60}</Moment></span>
                                ) :
                                  <Text disabled>N/A</Text>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The hash of the current Entry Credit Block."><RiQuestionLine /></Tooltip></IconContext.Provider>Header</nobr> Hash</span>}>
                                <span className="code break-all">{ecBlock.headerHash}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The hash of the Directory Block, that contains the current Entry Credit Block."><RiQuestionLine /></Tooltip></IconContext.Provider>Parent</nobr> DBlock</span>}>
                                    {ecBlock.parentDBlock ? (
                                        <span className="code break-all">
                                          <Link to={'/dblocks/' + ecBlock.parentDBlock.keyMR}>
                                            <IconContext.Provider value={{ className: 'react-icons' }}>
                                              <RiCheckboxMultipleBlankLine />
                                            </IconContext.Provider>
                                            {ecBlock.parentDBlock.keyMR}
                                          </Link>
                                        </span>
                                    ) : 
                                        <Text disabled>N/A</Text>
                                    }
                            </Descriptions.Item>
                        </Descriptions>

                        <Title level={4}>
                          <IconContext.Provider value={{ className: 'react-icons' }}>
                            <RiFileList2Line />
                          </IconContext.Provider>
                          Entry Credit Transactions
                          <Count count={ecTxs ? ecTxs.length : 0} />
                        </Title>

                        <Table
                            dataSource={ecTxs}
                            columns={columns}
                            pagination={pagination}
                            rowKey="entryhash"
                            scroll={{ x: 'max-content' }}
                        />

                    </div>
                ) :
                    <div>
                        {error ? (
                            <div class="skeleton-holder">
                                <Alert message={error} type="error" showIcon />
                            </div>
                        ) :
                            <div>
                                <Title level={4}>
                                  <IconContext.Provider value={{ className: 'react-icons' }}>
                                    <RiInformationLine />
                                  </IconContext.Provider>
                                  ECBlock Info
                                </Title>
                                <div class="skeleton-holder">
                                    <Skeleton active />
                                </div>
                                <Title level={4}>
                                  <IconContext.Provider value={{ className: 'react-icons' }}>
                                    <RiFileList2Line />
                                  </IconContext.Provider>
                                  Entry Credit Transactions
                                </Title>
                                <div class="skeleton-holder">
                                    <Skeleton active />
                                </div>
                            </div>
                        }
                    </div>
                }
        </div>
    );
}

export default ECBlock;
