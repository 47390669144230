import React, { useState, useEffect } from 'react';
import {
    Row,
    Col,
    Card,
    Carousel,
    Skeleton,
    Typography
} from 'antd';

import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

import axios from 'axios';

import { IconContext } from "react-icons";
import {
    RiFileList2Line, RiLinksLine, RiExchangeLine
} from 'react-icons/ri';

const { Title, Text } = Typography;

const Stats = props => {

  const [entries, setEntries] = useState(-1);
  const [chains, setChains] = useState(-1);
  const [txs, setTxs] = useState(-1);
  const [entriesChange, setEntriesChange] = useState(0);
  const [chainsChange, setChainsChange] = useState(0);
  const [txsChange, setTxsChange] = useState(0);
  const [entriesChangeClass, setEntriesChangeClass] = useState("0");
  const [chainsChangeClass, setChainsChangeClass] = useState("0");
  const [txsChangeClass, setTxsChangeClass] = useState("0");
  const [entriesChangeIcon, setEntriesChangeIcon] = useState(null);
  const [chainsChangeIcon, setChainsChangeIcon] = useState(null);
  const [txsChangeIcon, setTxsChangeIcon] = useState(null);

  const getStats = () => {
    axios.get('/explorer/stats')
    .then(function(response) {
        setEntries(response.data.result.entries);
        setChains(response.data.result.chains);
        setTxs(response.data.result.txs);

        if (response.data.result.prevEntries > 0) {
            let change = Math.round(((response.data.result.entries/response.data.result.prevEntries-1)*100) * 100) / 100;
            setEntriesChange(change);
            if (change > 0) {
                setEntriesChangeClass("up");
                setEntriesChangeIcon(<ArrowUpOutlined />);
            } else if (change < 0) {
                setEntriesChange(change*-1);
                setEntriesChangeClass("down");
                setEntriesChangeIcon(<ArrowDownOutlined />);
            }
        }
        if (response.data.result.prevChains > 0) {
            let change = Math.round(((response.data.result.chains/response.data.result.prevChains-1)*100) * 100) / 100;
            setChainsChange(change);
            if (change > 0) {
                setChainsChangeClass("up");
                setChainsChangeIcon(<ArrowUpOutlined />);
            } else if (change < 0) {
                setChainsChange(change*-1);
                setChainsChangeClass("down");
                setChainsChangeIcon(<ArrowDownOutlined />);
            }
        }
        if (response.data.result.prevTxs > 0) {
            let change = Math.round(((response.data.result.txs/response.data.result.prevTxs-1)*100) * 100) / 100;
            setTxsChange(change);
            if (change > 0) {
                setTxsChangeClass("up");
                setTxsChangeIcon(<ArrowUpOutlined />);
            } else if (change < 0) {
                setTxsChange(change*-1);
                setTxsChangeClass("down");
                setTxsChangeIcon(<ArrowDownOutlined />);
            }
        }

    });
  }

  useEffect(() => getStats(), []);

  return (
      <div>
        <Row gutter={[16,16]}>
          <Col xs={0} sm={8} md={8} lg={6} xl={5}>
            <Card>
                <span>
                    <IconContext.Provider value={{ className: 'react-icons react-icons-entries' }}><RiFileList2Line /></IconContext.Provider>
                    <br />
                    Entries <small>/ 7d</small>
                </span>
                {entries === -1 ? (
                    <Skeleton active paragraph={false} />
                ) : 
                    <div>
                    <Title level={3} className="code">{entries.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Title>
                    <Text className={"change change-"+entriesChangeClass}>{entriesChangeIcon}{entriesChange} %</Text>
                    </div>
                }
            </Card>
          </Col>
          <Col xs={0} sm={8} md={8} lg={6} xl={5}>
            <Card>
                <span>
                    <IconContext.Provider value={{ className: 'react-icons' }}><RiLinksLine /></IconContext.Provider>
                    <br />
                    Active Chains <small>/ 7d</small>
                </span>
                {chains === -1 ? (
                    <Skeleton active paragraph={false} />
                ) : 
                    <div>
                    <Title level={3} className="code">{chains.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Title>
                    <Text className={"change change-"+chainsChangeClass}>{chainsChangeIcon}{chainsChange} %</Text>
                    </div>
                }
            </Card>
          </Col>
          <Col xs={0} sm={8} md={8} lg={6} xl={5}>
            <Card>
                <span>
                    <IconContext.Provider value={{ className: 'react-icons' }}><RiExchangeLine /></IconContext.Provider>
                    <br />
                    Factoid Txs <small>/ 7d</small>
                </span>
                {txs === -1 ? (
                    <Skeleton active paragraph={false} />
                ) : 
                    <div>
                    <Title level={3} className="code">{txs.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Title>
                    <Text className={"change change-"+txsChangeClass}>{txsChangeIcon}{txsChange} %</Text>
                    </div>
                }
            </Card>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={0} md={0} lg={0} xl={0}>
            <Carousel autoplay>
                <Card>
                    <span>
                        <IconContext.Provider value={{ className: 'react-icons react-icons-entries' }}><RiFileList2Line /></IconContext.Provider>
                        <br />
                        Entries <small>/ 7d</small>
                    </span>
                    {entries === -1 ? (
                        <Skeleton active paragraph={false} />
                    ) : 
                        <div>
                        <Title level={3} className="code">{entries.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Title>
                        <Text className={"change change-"+entriesChangeClass}>{entriesChangeIcon}{entriesChange} %</Text>
                        </div>
                    }
                </Card>
                <Card>
                    <span>
                        <IconContext.Provider value={{ className: 'react-icons' }}><RiLinksLine /></IconContext.Provider>
                        <br />
                        Active Chains <small>/ 7d</small>
                    </span>
                    {chains === -1 ? (
                        <Skeleton active paragraph={false} />
                    ) : 
                        <div>
                        <Title level={3} className="code">{chains.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Title>
                        <Text className={"change change-"+chainsChangeClass}>{chainsChangeIcon}{chainsChange} %</Text>
                        </div>
                    }
                </Card>
                <Card>
                    <span>
                        <IconContext.Provider value={{ className: 'react-icons' }}><RiExchangeLine /></IconContext.Provider>
                        <br />
                        Factoid Txs <small>/ 7d</small>
                    </span>
                    {txs === -1 ? (
                        <Skeleton active paragraph={false} />
                    ) : 
                        <div>
                        <Title level={3} className="code">{txs.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Title>
                        <Text className={"change change-"+txsChangeClass}>{txsChangeIcon}{txsChange} %</Text>
                        </div>
                    }
                </Card>
            </Carousel>
          </Col>
        </Row>
      </div>
  );

};

export default Stats;
