import React, { useState, useEffect } from 'react';
import useInterval from 'use-interval'
import ReactGA from 'react-ga';

import { Link } from 'react-router-dom';
import { keyToPublicEcAddress, rcdHashToPublicFctAddress } from 'factom';

import Countdown, { zeroPad } from 'react-countdown';

import {
  Typography,
  Descriptions,
  Skeleton,
  Alert,
  Table,
  Divider,
  Tooltip,
  Row,
  Col,
  message,
  Tag
} from 'antd';

import { IconContext } from "react-icons";
import {
    RiCheckboxMultipleBlankLine, RiInformationLine, RiCouponLine, RiQuestionLine, RiExchangeBoxLine, RiExchangeLine, RiCopperCoinLine, RiCoinLine, RiCheckLine, RiCheckboxCircleLine, RiLoader4Line
} from 'react-icons/ri';

import Moment from 'react-moment';
import axios from 'axios';
import axiosRetry from 'axios-retry';

import { NotifyNetworkError } from './../common/Notifications';
import Count from './../common/Count';

const { Title, Text } = Typography;

const Transaction = ({ match }) => {

    const pagination = {defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '50', '100'], showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`};
    const [fTx, setFTx] = useState(null);
    const [inputs, setInputs] = useState(null);
    const [outputs, setOutputs] = useState(null);
    const [exchRate, setExchRate] = useState(null);
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);

    const CountdownRenderer = ({ minutes, seconds }) => {
        return <span>{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
    };
  
    const getTransaction = async (txID = txID, force = true) => {
        if (force) {
            document.title = "Transaction " + txID + " | Factom Realtime Explorer";
            setIsPending(false);
            setFTx(null);
            setInputs(null);
            setOutputs(null);
            setExchRate(null);
            setError(null);
        }
        try {
            const response = await axios.get('/explorer/ftransactions/'+txID);
            setFTx(response.data.result);
            setIsPending(false);
            if (force) {
                if (response.data.result.inputs) {
                    setInputs(response.data.result.inputs.map(x => ({address: x.address, amount: x.amount, addressType: "fct"})));
                }
                let allOutputs = [];
                if (response.data.result.outputs) {
                    allOutputs = [...allOutputs, ...response.data.result.outputs.map(x => ({address: x.address, amount: x.amount, addressType: "fct"}))];
                }
                if (response.data.result.outECs) {
                    allOutputs = [...allOutputs, ...response.data.result.outECs.map(x => ({address: x.address, amount: x.amount, addressType: "ec"}))];
                }
                setOutputs(allOutputs);
                if (response.data.result.parentFBlock.exchRate) {
                    setExchRate(response.data.result.parentFBlock.exchRate);
                }
            }
        }
        catch(error) {
            if (error.response) {
                if (force) {
                    const pendingRequest = axios.create();
                    const hide = message.loading('Checking pending transactions', 0);
                    axiosRetry(pendingRequest, { retries: 5, retryDelay: axiosRetry.exponentialDelay });
                    pendingRequest.get('/explorer/pending_ftransactions/'+txID)
                    .then(resp => {
                      setIsPending(true);
                      setFTx(resp.data.result);
                      if (resp.data.result.inputs) {
                        setInputs(resp.data.result.inputs.map(x => ({address: x.address, amount: x.amount, addressType: "fct"})));
                      }
                      let allOutputs = [];
                      if (resp.data.result.outputs) {
                        allOutputs = [...allOutputs, ...resp.data.result.outputs.map(x => ({address: x.address, amount: x.amount, addressType: "fct"}))];
                      }
                      if (resp.data.result.outECs) {
                        allOutputs = [...allOutputs, ...resp.data.result.outECs.map(x => ({address: x.address, amount: x.amount, addressType: "ec"}))];
                      }
                      setOutputs(allOutputs);
                      if (resp.data.result.exchRate) {
                        setExchRate(resp.data.result.exchRate);
                      }
                    })
                    .catch(error => {
                      if (error.response) {
                        setError(error.response.data.error);
                      } else {
                        NotifyNetworkError();
                      }
                    })
                    .finally(function() {
                      hide();
                    })
                }
            } else {
                NotifyNetworkError();
            }
        }
    }

    const columns = [
        {
          title: 'Address',
          className: 'code',
          render: row => {
            let address = "";
            let icon = "";
            if (row.addressType === "fct") {
                address = rcdHashToPublicFctAddress(row.address);
                icon = <RiCopperCoinLine />;
            }
            if (row.addressType === "ec") {
                address = keyToPublicEcAddress(row.address);
                icon = <RiCouponLine />;
            }
            return (
              <Link to={'/addresses/' + address}>
                <IconContext.Provider value={{ className: 'react-icons' }}>
                  {icon}
                </IconContext.Provider>
                {address}
              </Link>
              )
            }
        },
        {
          title: 'Amount',
          className: 'code',
          render: (row) => {
            let amount = 0;
            let currency = "";
            if (row.addressType === "fct") {
                amount = row.amount/100000000;
                currency = "FCT";
            }
            if (row.addressType === "ec") {
                amount = Math.round(row.amount/exchRate);
                currency = "EC";
            }
            return (
                <span>{amount} {currency}</span>
            )
          } 
        }
    ];

    useInterval(() => {
        getTransaction(match.params.txid, false);
    }, isPending ? 5000 : null);

    useEffect(() => {
      ReactGA.pageview(window.location.pathname);
      getTransaction(match.params.txid, true);
    }, [match.params.txid]);

    return (
        <div>
            <Title level={2}>Factoid Transaction</Title>
            <Title level={4} type="secondary" style={{ marginTop: "-10px" }} className="break-all" copyable>{match.params.txid}</Title>
                {fTx ? (
                    <div>
                        <Title level={4}>
                          <IconContext.Provider value={{ className: 'react-icons' }}>
                            <RiInformationLine />
                          </IconContext.Provider>
                          Transaction Info
                        </Title>
                        <Descriptions bordered column={1} size="middle">
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The block height of the parent DBlock, which indicates the length of the blockchain."><RiQuestionLine /></Tooltip></IconContext.Provider>Block</nobr> Height</span>}>
                                {fTx.parentDBlock ? (
                                  <span className="code">
                                    <Link to={'/dblocks/' + fTx.parentDBlock.keyMR}>
                                      {fTx.parentDBlock.dbHeight}
                                    </Link>
                                  </span>
                                ) :
                                  <Text disabled>N/A</Text>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The sequence number of the transaction inside the Factoid block."><RiQuestionLine /></Tooltip></IconContext.Provider>Sequence</nobr> Number</span>}>
                                {fTx.seqNumber >= 0 ? (
                                  <div>
                                  <span className="code" style={{marginRight: 15}}>
                                    {fTx.seqNumber}
                                  </span>
                                  {fTx.seqNumber === 0 ? (
                                    <Tooltip overlayClassName="explorer-tooltip" title="A transaction that creates new Factoids.">
                                        <Tag color="blue" style={{textTransform: "uppercase"}}><IconContext.Provider value={{ className: 'react-icons' }}><RiCoinLine /></IconContext.Provider>Coinbase</Tag>
                                    </Tooltip>
                                  ) :
                                    null
                                  }
                                  </div>
                                ) :
                                  <Text disabled>N/A</Text>                                
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The date and time at which a parent Directory Block is created."><RiQuestionLine /></Tooltip></IconContext.Provider>Timestamp</nobr> <nobr>(UTC+{-(new Date().getTimezoneOffset() / 60)})</nobr></span>}>
                                {fTx.parentDBlock ? (
                                    <span className="code"><Moment unix format="YYYY-MM-DD HH:mm" local>{fTx.parentDBlock.timestamp*60}</Moment></span>
                                ) : 
                                    <span>
                                    {fTx.createdAt ? (
                                        <span className="code"><Moment format="YYYY-MM-DD HH:mm" local>{fTx.createdAt}</Moment></span>
                                    ) :
                                        <Text disabled>N/A</Text>
                                    }
                                    </span>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="Also known as TXID. The hash of the current Factoid Transaction."><RiQuestionLine /></Tooltip></IconContext.Provider>Transaction</nobr> ID</span>}>
                                <span className="code break-all">{fTx.txId}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The status of the current Factoid Transaction."><RiQuestionLine /></Tooltip></IconContext.Provider>Status</nobr></span>}>
                                {fTx.parentDBlock ? (
                                    <Tooltip overlayClassName="explorer-tooltip" title="Added to the Directory Block.">
                                        <Tag color="green" style={{textTransform: "uppercase"}}><IconContext.Provider value={{ className: 'react-icons' }}><RiCheckboxCircleLine /></IconContext.Provider>Confirmed</Tag>
                                    </Tooltip>
                                ) :
                                    <div>
                                        <Tooltip overlayClassName="explorer-tooltip" title="Will be added to the next Directory Block.">
                                            <Tag color="blue" style={{textTransform: "uppercase"}}><IconContext.Provider value={{ className: 'react-icons' }}><RiLoader4Line className={'anticon-spin'} /></IconContext.Provider>Pending</Tag>
                                        </Tooltip>
                                        <Text type="secondary">≈ <Countdown date={Date.parse(fTx.createdAt) + 600000} zeroPadTime={2} renderer={CountdownRenderer} /></Text>
                                    </div>
                                }
                            </Descriptions.Item>
                            <Divider/>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The exchange rate for FCT→EC transactions in factoshis (Factoid^(-1e8))."><RiQuestionLine /></Tooltip></IconContext.Provider>Exchange</nobr> Rate</span>}>
                                {exchRate ? (
                                    <span className="code">{exchRate} <Text type="secondary">(1 FCT = {Math.round(1/exchRate*100000000)} EC)</Text></span>
                                ) :
                                    <Text disabled>N/A</Text>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The hash of the Factoid Block, that contains the current Factoid Transaction."><RiQuestionLine /></Tooltip></IconContext.Provider>FBlock</nobr></span>}>
                                    {fTx.parentFBlock ? (
                                        <span className="code break-all">
                                          <Link to={'/fblocks/' + fTx.parentFBlock.keyMR}>
                                            <IconContext.Provider value={{ className: 'react-icons' }}>
                                              <RiExchangeBoxLine />
                                            </IconContext.Provider>
                                            {fTx.parentFBlock.keyMR}
                                          </Link>
                                        </span>
                                    ) : 
                                        <Text disabled>N/A</Text>
                                    }
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The hash of the Directory Block, that contains the Parent Factoid Block."><RiQuestionLine /></Tooltip></IconContext.Provider>Parent</nobr> DBlock</span>}>
                                    {fTx.parentDBlock ? (
                                        <span className="code break-all">
                                          <Link to={'/dblocks/' + fTx.parentDBlock.keyMR}>
                                            <IconContext.Provider value={{ className: 'react-icons' }}>
                                              <RiCheckboxMultipleBlankLine />
                                            </IconContext.Provider>
                                            {fTx.parentDBlock.keyMR}
                                          </Link>
                                        </span>
                                    ) : 
                                        <Text disabled>N/A</Text>
                                    }
                            </Descriptions.Item>
                            <Divider/>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="Total amount of all inputs of the current Factoid Transaction."><RiQuestionLine /></Tooltip></IconContext.Provider>Total</nobr> Inputs</span>}>
                                <span className="code">{fTx.totalInputs/100000000} FCT</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="Total amount of all outputs of the current Factoid Transaction."><RiQuestionLine /></Tooltip></IconContext.Provider>Total</nobr> Outputs</span>}>
                                <span className="code">{fTx.totalOutputs/100000000} FCT</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="Total amount of Entry Credits created in the current Factoid Transaction."><RiQuestionLine /></Tooltip></IconContext.Provider>Total</nobr> ECs Created</span>}>
                                {exchRate ? (
                                    <span className="code">{Math.round(fTx.totalOutECs/exchRate)} EC</span>
                                ) :
                                    <Text disabled>N/A</Text>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The network fee of the current Factoid Transaction."><RiQuestionLine /></Tooltip></IconContext.Provider>Fees</nobr></span>}>
                                <span className="code">
                                    {fTx.totalInputs>0 ? (
                                        <span>{(fTx.totalInputs-fTx.totalOutputs-fTx.totalOutECs)/100000000}</span>
                                    ) :
                                        0
                                    } FCT</span>
                            </Descriptions.Item>
                        </Descriptions>

                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                <Title level={4}>
                                <IconContext.Provider value={{ className: 'react-icons' }}>
                                    <RiExchangeLine />
                                </IconContext.Provider>
                                Inputs
                                <Count count={inputs ? inputs.length : 0} />
                                </Title>

                                <Table
                                    dataSource={inputs}
                                    columns={columns}
                                    pagination={pagination}
                                    rowKey="entryhash"
                                    scroll={{ x: 'max-content' }}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                            <Title level={4}>
                                <IconContext.Provider value={{ className: 'react-icons' }}>
                                    <RiExchangeLine />
                                </IconContext.Provider>
                                Outputs
                                <Count count={outputs ? outputs.length : 0} />
                                </Title>

                                <Table
                                    dataSource={outputs}
                                    columns={columns}
                                    pagination={pagination}
                                    rowKey="entryhash"
                                    scroll={{ x: 'max-content' }}
                                />
                            </Col>
                        </Row>

                    </div>
                ) :
                    <div>
                        {error ? (
                            <div class="skeleton-holder">
                                <Alert message={error} type="error" showIcon />
                            </div>
                        ) :
                            <div>
                                <Title level={4}>
                                  <IconContext.Provider value={{ className: 'react-icons' }}>
                                    <RiInformationLine />
                                  </IconContext.Provider>
                                  Transaction Info
                                </Title>
                                <div class="skeleton-holder">
                                    <Skeleton active />
                                </div>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                        <Title level={4}>
                                        <IconContext.Provider value={{ className: 'react-icons' }}>
                                            <RiExchangeLine />
                                        </IconContext.Provider>
                                        Inputs
                                        </Title>
                                        <div class="skeleton-holder">
                                            <Skeleton active />
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                        <Title level={4}>
                                        <IconContext.Provider value={{ className: 'react-icons' }}>
                                            <RiExchangeLine />
                                        </IconContext.Provider>
                                        Outputs
                                        </Title>
                                        <div class="skeleton-holder">
                                            <Skeleton active />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        }
                    </div>
                }
        </div>
    );
}

export default Transaction;
