
import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';

import { Link } from 'react-router-dom';

import { isValidFctAddress } from 'factom';

import {
  Typography,
  Alert,
  Descriptions,
  Tooltip,
  Skeleton,
  message,
  Table,
  Tag,
} from 'antd';

import { IconContext } from "react-icons";
import {
    RiExchangeLine, RiInformationLine, RiQuestionLine, RiCoinLine, RiArrowLeftRightLine
} from 'react-icons/ri';

import Moment from 'react-moment';
import axios from 'axios';

import { NotifyNetworkError } from './../common/Notifications';
import Count from './../common/Count';

const { Title } = Typography;

const Address = ({ match }) => {

  const [addressInfo, setAddressInfo] = useState(null);
  const [addressTxs, setAddressTxs] = useState(null);
  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [totalTxs, setTotalTxs] = useState(-1);
  const [pagination, setPagination] = useState({pageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '50', '100'], current: 1});
  const [error, setError] = useState(null);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const getFilters = (address = match.params.address) => {
    if (isValidFctAddress(address)) {
      return [
        { text: 'Incoming', value: 'in' },
        { text: 'Outgoing', value: 'out' },
      ];
    } else {
      return null;
    }
  }

  const getAddress = async (address = address) => {
    document.title = "Address " + address + " | Factom Realtime Explorer";
    source.cancel();
    setAddressInfo(null);
    setAddressTxs(null);
    setTotalTxs(-1);
    setError(null);
    setPagination({...pagination, current: 1});
    try {
        const response = await axios.get('/explorer/addresses/'+address);
        setAddressInfo(response.data.result);
    }
    catch(error) {
        if (error.response) {
            setError(error.response.data.error);
        } else {
            NotifyNetworkError();
        }
    }
  }

  const getAddressTxs = async (params = pagination, filters, sorter) => {
    ReactGA.pageview(window.location.pathname);
    setTableIsLoading(true);

    let start = 0;
    let limit = 10;
    let showTotalStart = 1;
    let showTotalFinish = 10;
    let sort = "desc";
    let filter = "";

    if (params) {
        start = (params.current-1)*params.pageSize;
        limit = params.pageSize;
        showTotalStart = (params.current-1)*params.pageSize+1;
        showTotalFinish = params.current*params.pageSize;
    }

    if (sorter) {
      switch (sorter.order) {
          case 'ascend':
              sort = "asc";
              break;
          case 'descend':
              sort = "desc";
              break;
      }
    }

    if (filters && filters[1] && filters[1] !== null && filters[1].length === 1) {
      filter = filters[1][0];
    }

    try {
      const response = await axios.get('/explorer/addresses/'+addressInfo.pubAddress+'/txs', { params: { start: start, limit: limit, sort: sort, filter: filter }, cancelToken: source.token } );
      setAddressTxs(response.data.result);
      setPagination({...pagination, current: (response.data.start/response.data.limit)+1, pageSize: response.data.limit, total: response.data.total, showTotal: (total, range) => `${showTotalStart}-${Math.min(response.data.total, showTotalFinish)} of ${response.data.total}`});
      setTotalTxs(response.data.total);
    }
    catch(error) {
        if (error.response) {
            if (error.response.data.error) {
              message.error(error.response.data.error);
            }
        } else {
          if (!axios.isCancel(error)) {
            NotifyNetworkError();
          }
        }
    }
    setTableIsLoading(false);
  }

  const columns = [
    {
      title: 'Timestamp (UTC+'+ -(new Date().getTimezoneOffset() / 60) + ')',
      dataIndex: 'timestamp',
      className: 'code',
      sorter: true,
      width: 30,
      render: (timestamp) => (
        <nobr><Moment unix format="YYYY-MM-DD HH:mm" local>{timestamp*60}</Moment></nobr>
      )
    },
    {
      title: 'Type',
      width: 30,
      filters: getFilters(),
      render: (row) => {
        let txIcon = <IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="Regular Transaction"><RiArrowLeftRightLine /></Tooltip></IconContext.Provider>
         if (addressInfo.type === "FCT") {
            if (row.seqNumber === 0) {
              txIcon = <IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="Coinbase Transaction"><RiCoinLine /></Tooltip></IconContext.Provider>
            }
            if (addressInfo.pubKey === row.inputs.address) {
              return (
                <Tag color="volcano" style={{textTransform: "uppercase"}}>
                  {txIcon}
                  Outgoing
                </Tag>
              )
            }
            if (addressInfo.pubKey === row.outputs.address) {
              return (
                <Tag color="green" style={{textTransform: "uppercase"}}>
                  {txIcon}
                  Incoming
                </Tag>
              )
            }  
        }
        else if (addressInfo.type === "EC") {
          return (
            <Tag color="green"style={{textTransform: "uppercase"}} >
              {txIcon}
              Incoming
            </Tag>
          )
        }
      }
    },
    {
        title: 'Transaction ID',
        dataIndex: 'txId',
        className: 'code',
        render: (txId) => (
            <Link to={'/transactions/' + txId}>
                <IconContext.Provider value={{ className: 'react-icons' }}>
                    <RiExchangeLine />
                </IconContext.Provider>
                {txId}
            </Link>
        )
    },
    {
      title: 'Balance Change',
      className: 'code',
      render: (row) => {
        var balanceChange = 0;
        if (addressInfo.type === "FCT") {
          if (addressInfo.pubKey === row.inputs.address) {
            balanceChange = balanceChange-row.inputs.amount;
          }
          if (addressInfo.pubKey === row.outputs.address) {
            balanceChange = balanceChange+row.outputs.amount;
          }
          balanceChange = balanceChange/100000000;
          balanceChange = balanceChange.toString().replace(/-/g, '–');
        }
        else if (addressInfo.type === "EC") {
          balanceChange = Math.round(row.outECs.amount/row.exchRate);
        }
        return (
          <span>{balanceChange} {addressInfo.type}</span>
        )
      }
    },
  ];

  useEffect(() => {
    if (addressInfo) {
        getAddressTxs();
    }
  }, [addressInfo]);

  useEffect(() => {
      getAddress(match.params.address);
  }, [match.params.address]);

  useEffect(() => {
    // returned function will be called on component unmount 
    return () => {
      source.cancel();
    }
  }, []);

  return (
      <div>
          <Title level={2}>
            Address
          </Title>
          <Title level={4} type="secondary" style={{ marginTop: "-10px" }} className="break-all" copyable>{match.params.address}</Title>
            {addressInfo ? (
              <div>
                <Title level={4}>
                  <IconContext.Provider value={{ className: 'react-icons' }}>
                    <RiInformationLine />
                  </IconContext.Provider>
                  Address Info
                </Title>
                <Descriptions bordered column={1} size="middle" className="description-address">
                  <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="Human readable public key of the current Address."><RiQuestionLine /></Tooltip></IconContext.Provider>Address</nobr></span>}>
                      <span className="code break-all">{addressInfo.pubAddress}</span>
                  </Descriptions.Item>
                  <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="Type of address: Factoid (FCT) or Entry Credit (EC)."><RiQuestionLine /></Tooltip></IconContext.Provider>Address</nobr> Type</span>}>
                      <span>{isValidFctAddress(addressInfo.pubAddress) ? "Factoid" : "Entry Credit"} Address</span>
                  </Descriptions.Item>
                  <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="Balance of the current Address."><RiQuestionLine /></Tooltip></IconContext.Provider>Balance</nobr></span>}>
                      <span className="code">
                      {addressInfo.type === "FCT" ? addressInfo.balance/100000000 : addressInfo.balance} {addressInfo.type}
                      </span>
                  </Descriptions.Item>
                </Descriptions>
                <Title level={4}>
                  <IconContext.Provider value={{ className: 'react-icons' }}>
                    <RiExchangeLine />
                  </IconContext.Provider>
                  Transactions
                  <Count count={totalTxs ? totalTxs : 0} />
                </Title>

                <Table
                    dataSource={addressTxs}
                    columns={columns}
                    pagination={pagination}
                    rowKey="txId"
                    loading={tableIsLoading}
                    onChange={getAddressTxs}
                    scroll={{ x: 'max-content' }}
                />
              </div>
            ) :
            <div>
              {error ? (
                  <div class="skeleton-holder">
                      <Alert message={error} type="error" showIcon />
                  </div>
              ) :
                  <div>
                      <Title level={4}>
                        <IconContext.Provider value={{ className: 'react-icons' }}>
                          <RiInformationLine />
                        </IconContext.Provider>
                        Address Info
                      </Title>
                      <div class="skeleton-holder">
                          <Skeleton active />
                      </div>
                      <Title level={4}>
                        <IconContext.Provider value={{ className: 'react-icons' }}>
                          <RiExchangeLine />
                        </IconContext.Provider>
                        Transactions
                      </Title>
                      <div class="skeleton-holder">
                          <Skeleton active />
                      </div>
                  </div>
              }
            </div>
            }
      </div>
  );
}

export default Address;
