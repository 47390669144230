import React, { useState, useEffect } from 'react';
import useInterval from 'use-interval'
import ReactGA from 'react-ga';

import { Link } from 'react-router-dom';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import monoBlue from 'react-syntax-highlighter/dist/esm/styles/hljs/mono-blue';
import { Base64 } from 'js-base64';

import {
  Typography,
  Descriptions,
  Skeleton,
  Alert,
  Table,
  Tooltip,
  message,
  Tag,
  Radio,
  Tabs
} from 'antd';

import { IconContext } from "react-icons";
import {
    RiInformationLine, RiFileList2Line, RiQuestionLine, RiPriceTag3Line, RiFileCopy2Line, RiCheckboxCircleLine, RiLoader4Line
} from 'react-icons/ri';

import Countdown, { zeroPad } from 'react-countdown';

import Moment from 'react-moment';
import moment from 'moment';
import axios from 'axios';
import axiosRetry from 'axios-retry';

import { NotifyNetworkError } from './../common/Notifications';
import Count from './../common/Count';
import ExtID from './../common/ExtID';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const Chain = ({ match }) => {

    const [chain, setChain] = useState(null);
    const [firstEntry, setFirstEntry] = useState(null);
    const [tableIsLoading, setTableIsLoading] = useState(true);
    const [entries, setEntries] = useState(null);
    const [totalEntries, setTotalEntries] = useState(-1);
    const [pagination, setPagination] = useState({pageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '50', '100'], current: 1});
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);

    const defaultType = "ASCII";
    const [type, setType] = useState(defaultType);
    const [jsonDisabled, setJSONDisabled] = useState(true);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const handleChange = event => {
      setType(event.target.value);
    };

    const CountdownRenderer = ({ minutes, seconds }) => {
      return <span>{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
    };

    function IsJsonString(str) {
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
    }

    const getChain = async (chainID = chainID, force = true) => {
        if (force) {
          document.title = "Chain " + chainID + " | Factom Realtime Explorer";
          source.cancel();
          setIsPending(false);
          setChain(null);
          setEntries(null);
          setTotalEntries(-1);
          setError(null);
          setPagination({...pagination, current: 1});
        }
        try {
            const response = await axios.get('/explorer/chains/'+chainID);
            setIsPending(false);
            setChain(response.data.result);
            const firstEntryResponse = await axios.get('/explorer/chains/'+chainID+'/entries/first');
            if (firstEntryResponse.data.result) {
              setFirstEntry(firstEntryResponse.data.result);
              if ([...Base64.decode(firstEntryResponse.data.result.content)].some(char => char.charCodeAt(0) < 32 || (char.charCodeAt(0) > 127 && char.charCodeAt(0) < 256))) {
                setType("Base64");
              } else {
                if (IsJsonString(Base64.decode(firstEntryResponse.data.result.content))) {
                  setJSONDisabled(false);
                  setType("JSON");
                } else {
                  setType("ASCII");
                }
              }
            }
        }
        catch(error) {
            if (error.response) {
              if (force) {
                const pendingRequest = axios.create();
                const hide = message.loading('Checking pending chains', 0);
                axiosRetry(pendingRequest, { retries: 5, retryDelay: axiosRetry.exponentialDelay });
                pendingRequest.get('/explorer/pending_chains/'+chainID)
                .then(resp => {
                  setIsPending(true);
                  setChain(resp.data.result);
                })
                .catch(error => {
                  if (error.response) {
                    setError(error.response.data.error);
                  } else {
                    NotifyNetworkError();
                  }
                })
                .finally(function() {
                  ReactGA.pageview(window.location.pathname);
                  hide();
                })
              }
            } else {
                NotifyNetworkError();
            }
        }
    }

    const getEntries = async (params = pagination, filters, sorter) => {
        ReactGA.pageview(window.location.pathname);
        setTableIsLoading(true);

        let start = 0;
        let limit = 10;
        let showTotalStart = 1;
        let showTotalFinish = 10;
        let sort = "desc";

        if (params) {
            start = (params.current-1)*params.pageSize;
            limit = params.pageSize;
            showTotalStart = (params.current-1)*params.pageSize+1;
            showTotalFinish = params.current*params.pageSize;
        }

        if (sorter) {
          switch (sorter.order) {
              case 'ascend':
                  sort = "asc";
                  break;
              case 'descend':
                  sort = "desc";
                  break;
          }
        }

        try {
          const response = await axios.get('/explorer/chains/'+chain.chainId+'/entries', { params: { start: start, limit: limit, sort: sort }, cancelToken: source.token } );
          setEntries(response.data.result);
          setPagination({...pagination, current: (response.data.start/response.data.limit)+1, pageSize: response.data.limit, total: response.data.total, showTotal: (total, range) => `${showTotalStart}-${Math.min(response.data.total, showTotalFinish)} of ${response.data.total}`});
          setTotalEntries(response.data.total);
        }
        catch(error) {
            if (error.response) {
              if (error.response.data.error) {
                  message.error(error.response.data.error);
              }
            } else {
                if (!axios.isCancel(error)) {
                    NotifyNetworkError();
                }
            }
        }
        setTableIsLoading(false);
    }

    const columns = [
        {
            title: 'Timestamp (UTC+'+ -(new Date().getTimezoneOffset() / 60) + ')',
            className: 'code',
            sorter: true,
            width: 30,
            render: (row) => {
              let entryTime = moment(row.createdAt);
              let entryBlockTime = moment(row.entryBlockTime);
              let diff = moment.duration(entryTime.diff(entryBlockTime)).asMinutes();
              if (diff < 60) {
                return (
                  <nobr><Moment format="YYYY-MM-DD HH:mm" local>{row.createdAt}</Moment></nobr>
                )
              }
              return (
                <nobr><Moment format="YYYY-MM-DD HH:mm" local>{row.entryBlockTime}</Moment></nobr>
              )
          }
        },
        {
            title: 'Entry Hash',
            dataIndex: 'entryHash',
            className: 'code',
            render: (entryHash) => (
                <Link to={'/entries/'+entryHash}>
                    <IconContext.Provider value={{ className: 'react-icons' }}>
                        <RiFileList2Line />
                    </IconContext.Provider>
                    {entryHash}
                </Link>
            )
        },
        {
          title: 'Entry Block',
          dataIndex: 'entryBlock',
          className: 'code',
          render: (entryBlock) => (
              <Link to={'/eblocks/'+entryBlock}>
                  <IconContext.Provider value={{ className: 'react-icons' }}>
                      <RiFileCopy2Line />
                  </IconContext.Provider>
                  {entryBlock}
              </Link>
          )
      },
      {
          title: 'External IDs',
          dataIndex: 'extIds',
          render: (extIds) => {
            if (extIds !== null) {
              var items = extIds.slice(0,3).map((item) => <ExtID compact>{item}</ExtID>);
              let extra = extIds.length-3;
              if (extra > 0) {
                items.push(<Tag className="extid-tag">+{extra} more</Tag>);
              }
              return <nobr>{items}</nobr>;
            } else {
              return null;
            }
          }
      },
    ];

    useInterval(() => {
      getChain(match.params.chainid, false);
    }, isPending ? 5000 : null);

    useEffect(() => {
        if (chain && !isPending) {
            getEntries();
        }
    }, [chain]);

    useEffect(() => {
      getChain(match.params.chainid, true);
    }, [match.params.chainid]);

    useEffect(() => {
      // returned function will be called on component unmount 
      return () => {
        source.cancel();
      }
    }, []);

    return (
        <div>
            <Title level={2}>Chain</Title>
            <Title level={4} type="secondary" style={{ marginTop: "-10px" }} className="break-all" copyable>{match.params.chainid}</Title>
                {chain ? (
                    <div>
                        <Title level={4}>
                          <IconContext.Provider value={{ className: 'react-icons' }}>
                            <RiInformationLine />
                          </IconContext.Provider>
                          Chain Info
                        </Title>
                        <Descriptions bordered column={1} size="middle">
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The date and time at which a chain is created."><RiQuestionLine /></Tooltip></IconContext.Provider>Timestamp</nobr> <nobr>(UTC+{-(new Date().getTimezoneOffset() / 60)})</nobr></span>}>
                                {chain.createdAt ? (
                                  <span className="code"><Moment format="YYYY-MM-DD HH:mm" local>{chain.createdAt}</Moment></span>
                                ) :
                                  <Text disabled>N/A</Text>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The hash of the current Chain."><RiQuestionLine /></Tooltip></IconContext.Provider>Chain</nobr> ID</span>}>
                                <span className="code break-all">{chain.chainId}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={<span><nobr><IconContext.Provider value={{ className: 'react-icons' }}><Tooltip overlayClassName="explorer-tooltip" title="The status of the current Chain."><RiQuestionLine /></Tooltip></IconContext.Provider>Status</nobr></span>}>
                                {chain.status === "completed" ? (
                                  <Tooltip overlayClassName="explorer-tooltip" title="Added to the Directory Block.">
                                    <Tag color="green" style={{textTransform: "uppercase"}}><IconContext.Provider value={{ className: 'react-icons' }}><RiCheckboxCircleLine /></IconContext.Provider>Confirmed</Tag>
                                  </Tooltip>
                                ) : 
                                  <div>
                                  <Tooltip overlayClassName="explorer-tooltip" title="Will be added to the next Directory Block.">
                                    <Tag color="blue" style={{textTransform: "uppercase"}}><IconContext.Provider value={{ className: 'react-icons' }}><RiLoader4Line className={'anticon-spin'} /></IconContext.Provider>Pending</Tag>
                                  </Tooltip>
                                  <Text type="secondary">≈ <Countdown date={Date.parse(chain.createdAt) + 600000} zeroPadTime={2} renderer={CountdownRenderer} /></Text>
                                  </div>
                                }
                            </Descriptions.Item>
                        </Descriptions>

                        {!isPending ? (

                        <div>

                        <Title level={4}>
                          <nobr>
                          <IconContext.Provider value={{ className: 'react-icons' }}>
                            <RiPriceTag3Line />
                          </IconContext.Provider>
                          External IDs
                          <IconContext.Provider value={{ className: 'react-icons-tooltip' }}>
                            <Tooltip overlayClassName="explorer-tooltip" title="External IDs (ExtID) are intended to provide any sort of tagging information for Chains and Entries that applications may find useful.">
                              <RiQuestionLine />
                            </Tooltip>
                          </IconContext.Provider>
                          </nobr>
                        </Title>

                        {chain.extIds ? (
                            <div className="extids">
                              {chain.extIds.map((item) => <ExtID>{item}</ExtID>)}
                            </div>
                          ) :
                            <div class="skeleton-holder">
                              <Alert message="No external IDs" type="info" showIcon />
                            </div>
                        }

                        <Title level={4}>
                          <nobr>
                          <IconContext.Provider value={{ className: 'react-icons' }}>
                            <RiFileList2Line />
                          </IconContext.Provider>
                          First Entry
                          <IconContext.Provider value={{ className: 'react-icons-tooltip' }}>
                            <Tooltip overlayClassName="explorer-tooltip" title="The content of the first Entry of this Chain.">
                              <RiQuestionLine />
                            </Tooltip>
                          </IconContext.Provider>
                          </nobr>
                        </Title>

                        {firstEntry ? (
                          <div>
                          {firstEntry.content ? (
                            <div>
                            <Radio.Group defaultValue={defaultType} value={type} buttonStyle="solid" className="type-radio" onChange={handleChange}>
                              <Radio.Button value="ASCII" className="type-ASCII">ASCII</Radio.Button>
                              <Radio.Button value="JSON" className="type-JSON" disabled={jsonDisabled}>JSON</Radio.Button>
                              <Radio.Button value="Base64" className="type-Base64">Base64</Radio.Button>
                              <Radio.Button value="Hex" className="type-Hex">Hex</Radio.Button>
                            </Radio.Group>

                            <Tabs defaultActiveKey={defaultType} activeKey={type} className="entry-content" style={{marginTop: 15, marginBottom: 30}}>
                              <TabPane key="ASCII">
                                <div className="content break-all"><Text copyable>{Base64.decode(firstEntry.content)}</Text></div>
                              </TabPane>
                              <TabPane key="JSON">
                                {!jsonDisabled ? ( <SyntaxHighlighter language="json" style={monoBlue}>{JSON.stringify(JSON.parse(Base64.decode(firstEntry.content)), null, 4)}</SyntaxHighlighter> ) : null}
                              </TabPane>
                              <TabPane key="Base64">
                                <div className="content code break-all"><Text copyable>{firstEntry.content}</Text></div>
                              </TabPane>
                              <TabPane key="Hex">
                                <div className="content code break-all"><Text copyable>{Buffer.from(firstEntry.content, 'base64').toString('hex')}</Text></div>
                              </TabPane>
                            </Tabs>
                            </div>
                          ) :
                            <div class="skeleton-holder">
                              <Alert message="No content" type="info" showIcon />
                            </div>
                          }
                          </div>
                        ) :
                          <div class="skeleton-holder">
                            <Skeleton active />
                          </div>
                        }

                        <Title level={4}>
                          <IconContext.Provider value={{ className: 'react-icons' }}>
                            <RiFileList2Line />
                          </IconContext.Provider>
                          Entries
                          <Count count={totalEntries ? totalEntries : 0} />
                        </Title>

                        <Table
                            dataSource={entries}
                            columns={columns}
                            pagination={pagination}
                            rowKey="entryHash"
                            loading={tableIsLoading}
                            onChange={getEntries}
                            scroll={{ x: 'max-content' }}
                        />

                        </div>

                        ) :
                          null
                        }

                    </div>
                ) :
                    <div>
                        {error ? (
                            <div class="skeleton-holder">
                                <Alert message={error} type="error" showIcon />
                            </div>
                        ) :
                            <div>
                                <Title level={4}>
                                  <IconContext.Provider value={{ className: 'react-icons' }}>
                                    <RiInformationLine />
                                  </IconContext.Provider>
                                  Chain Info
                                </Title>
                                <div class="skeleton-holder">
                                    <Skeleton active />
                                </div>
                                <Title level={4}>
                                  <IconContext.Provider value={{ className: 'react-icons' }}>
                                    <RiPriceTag3Line />
                                  </IconContext.Provider>
                                  External IDs
                                </Title>
                                <div class="skeleton-holder">
                                    <Skeleton active />
                                </div>
                                <Title level={4}>
                                  <IconContext.Provider value={{ className: 'react-icons' }}>
                                    <RiFileList2Line />
                                  </IconContext.Provider>
                                  First Entry
                                </Title>
                                <div class="skeleton-holder">
                                    <Skeleton active />
                                </div>
                                <Title level={4}>
                                  <IconContext.Provider value={{ className: 'react-icons' }}>
                                    <RiFileList2Line />
                                  </IconContext.Provider>
                                  Entries
                                </Title>
                                <div class="skeleton-holder">
                                    <Skeleton active />
                                </div>
                            </div>
                        }
                    </div>
                }
        </div>
    );
}

export default Chain;
