import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';

import { Link } from 'react-router-dom';

import Moment from 'react-moment';
import axios from 'axios';

import {
  Typography,
  Table,
  message,
  Button
} from 'antd';

import { ReloadOutlined } from '@ant-design/icons';

import { IconContext } from "react-icons";
import {
    RiExchangeLine, RiFileList2Line
} from 'react-icons/ri';

import { NotifyNetworkError } from './../common/Notifications';
import Count from './../common/Count';

const { Title } = Typography;

const Pending = () => {

  const [tableTxsIsLoading, setTableTxsIsLoading] = useState(true);
  const [tableEntriesIsLoading, setTableEntriesIsLoading] = useState(true);
  const [paginationTxs, setPaginationTxs] = useState({pageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '50', '100'], current: 1});
  const [paginationEntries, setPaginationEntries] = useState({pageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '50', '100'], current: 1});
  const [pendingTxs, setPendingTxs] = useState([]);
  const [pendingEntries, setPendingEntries] = useState([]);
  const [totalPendingTxs, setTotalPendingTxs] = useState(-1);
  const [totalPendingEntries, setTotalPendingEntries] = useState(-1);

  const getPendingTxs = async (params = paginationTxs) => {
    ReactGA.pageview(window.location.pathname);
    setTableTxsIsLoading(true);
    try {
      const response = await axios.get('/explorer/pending_ftransactions', { params: { start: (params.current-1)*params.pageSize, limit: params.pageSize } });
      setPendingTxs(response.data.result.Items);
      setTotalPendingTxs(response.data.total);
      setPaginationTxs({...paginationTxs, current: (response.data.start/response.data.limit)+1, pageSize: response.data.limit, total: response.data.total, showTotal: (total, range) => `${(params.current-1)*params.pageSize+1}-${Math.min(response.data.total, params.current*params.pageSize)} of ${response.data.total}`});
    }
    catch(error) {
        if (error.response) {
          message.error(error.response.data.error);
        } else {
          NotifyNetworkError();
        }
    }
    setTableTxsIsLoading(false);
  }

  const getPendingEntries = async (params = paginationEntries) => {
    ReactGA.pageview(window.location.pathname);
    setTableEntriesIsLoading(true);
    try {
      const response = await axios.get('/explorer/pending_entries', { params: { start: (params.current-1)*params.pageSize, limit: params.pageSize } });
      setPendingEntries(response.data.result.Items);
      setTotalPendingEntries(response.data.total);
      setPaginationEntries({...paginationEntries, current: (response.data.start/response.data.limit)+1, pageSize: response.data.limit, total: response.data.total, showTotal: (total, range) => `${(params.current-1)*params.pageSize+1}-${Math.min(response.data.total, params.current*params.pageSize)} of ${response.data.total}`});
    }
    catch(error) {
        if (error.response) {
          message.error(error.response.data.error);
        } else {
          NotifyNetworkError();
        }
    }
    setTableEntriesIsLoading(false);
  }

  const columnsTxs = [
    {
        title: 'Time',
        dataIndex: 'createdAt',
        width: 120,
        render: (createdAt) => (
            <nobr><Moment fromNow>{createdAt}</Moment></nobr>
        )
    },
    {
        title: 'Transaction ID',
        dataIndex: 'txId',
        className: 'code',
        render: (txId) => (
            <Link to={'/transactions/' + txId}>
                <IconContext.Provider value={{ className: 'react-icons' }}>
                    <RiExchangeLine />
                </IconContext.Provider>
                {txId}
            </Link>
        )
    },
    {
        title: 'Inputs',
        dataIndex: 'totalInputs',
        className: 'code',
        render: (totalInputs) => (
          <span>{totalInputs/100000000} FCT</span>
        )
    },
    {
        title: 'Outputs',
        dataIndex: 'totalOutputs',
        className: 'code',
        render: (totalOutputs) => (
          <span>{totalOutputs/100000000} FCT</span>
        )
    },
    {
        title: 'ECs Created',
        className: 'code',
        render: (row) => (
          <span>{Math.round(row.totalOutECs/row.exchRate)} EC</span>
        )
    },
    {
        title: 'Fees',
        className: 'code',
        render: (row) => {
          let fees = 0;
          if (row.totalInputs>0) { fees = row.totalInputs-row.totalOutputs-row.totalOutECs; }
          return (
            <span>{fees/100000000} FCT</span>
          )
        }
    },
  ];

  const columnsEntries = [
    {
        title: 'Time',
        dataIndex: 'createdAt',
        width: 120,
        render: (createdAt) => (
            <nobr><Moment fromNow>{createdAt}</Moment></nobr>
        )
    },
    {
        title: 'Entry Hash',
        dataIndex: 'entryHash',
        className: 'code',
        render: (entryHash) => (
            <Link to={'/entries/' + entryHash}>
                <IconContext.Provider value={{ className: 'react-icons' }}>
                    <RiFileList2Line />
                </IconContext.Provider>
                {entryHash}
            </Link>
        )
    },
  ];

  useEffect(() => {
    document.title = "Pending Transactions and Entries | Factom Realtime Explorer";
    getPendingTxs();
    getPendingEntries();
  }, []);

  return (
    <div>

      <Title level={2}>
          Pending Transactions and Entries
      </Title>
      <Title level={4} type="secondary" style={{ marginTop: "-10px" }}>Data to be included in the next Directory Block</Title>

      <Title level={4}>
        <IconContext.Provider value={{ className: 'react-icons' }}>
          <RiExchangeLine />
        </IconContext.Provider>
        Transactions<Count count={totalPendingTxs} />
        <Button
            type="link"
            icon={<ReloadOutlined />}
            loading={tableTxsIsLoading}
            onClick={() => getPendingTxs(paginationTxs)}
            className="refresh-button"
        />
      </Title>

      <Table
        dataSource={pendingTxs}
        columns={columnsTxs}
        pagination={paginationTxs}
        rowKey="txId"
        loading={tableTxsIsLoading}
        onChange={getPendingTxs}
        scroll={{ x: 'max-content' }}
      />

      <Title level={4} style={{ marginTop: pendingTxs ? 25 : 0 }}>
          <IconContext.Provider value={{ className: 'react-icons' }}>
            <RiFileList2Line />
          </IconContext.Provider>
          Entries<Count count={totalPendingEntries} />
          <Button
            type="link"
            icon={<ReloadOutlined />}
            loading={tableEntriesIsLoading}
            onClick={() => getPendingEntries(paginationEntries)}
            className="refresh-button"
          />
      </Title>

      <Table
        dataSource={pendingEntries}
        columns={columnsEntries}
        pagination={paginationEntries}
        rowKey="entryHash"
        loading={tableEntriesIsLoading}
        onChange={getPendingEntries}
        scroll={{ x: 'max-content' }}
      />

    </div>
  );
}

export default Pending;
